@use "sass:color";

$sidebar-root-transition-time: 200ms;

.details-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: flex-end;

  &--hidden {
    transition: all 0ms $sidebar-root-transition-time ease-in-out;
    transform: scale(0);
    opacity: 0;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity $sidebar-root-transition-time ease-in-out;
    background-color: color.change($color-black, $alpha: 0.2);

    &--hidden {
      opacity: 0;
    }
  }

  &__root {
    background-color: $color-white;
    width: 400px;
    transition: all $sidebar-root-transition-time ease-in-out;
    transform: translateX(0%);
    display: flex;
    flex-direction: column;

    &--hidden {
      transform: translateX(100%);
    }
  }

  &__header {
    padding-inline-start: 20px;
    padding-inline-end: 32px;
    padding-block: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
  }

  &__title {
    color: $color-text-primary;
    font-size: toRem(20);
    font-weight: 500;
  }

  &__dismiss-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }

  &__separator {
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    background-color: $color-main-border;
    width: 100%;
  }

  &__body {
    padding-block: 20px;
    height: 100%;
    overflow-y: auto;
    scrollbar-color: $color-mischka $color-white;
    scrollbar-width: thin;
  }
}

.details-sidebar-body {
  &__section {
    margin: 40px 20px;
  }

  &__section-message {
    margin-top: 12px;
    color: $color-regent-gray;
    text-align: justify;
    word-wrap: break-word;
    font-size: toRem(14);
  }

  &__user-info {
    display: flex;
    text-align: center;
    align-items: center;
    margin: 20px 20px 40px;
    flex-direction: column;
  }

  &__user-info-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  &__user-info-initials {
    line-height: 0;
    width: 56px;
    height: 56px;
    font-size: toRem(19.2);
    color: $color-text-secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 50%;
    text-transform: uppercase;
    background-color: $color-athens-gray-2;
  }

  &__user-info-name {
    margin-top: 12px;
    font-weight: 500;
    font-size: toRem(16);
    color: $color-text-primary;
    unicode-bidi: "plaintext";
    overflow-wrap: anywhere;
  }

  &__user-info-iban {
    margin-top: 4px;
    font-weight: 400;
    font-size: toRem(14);
    color: $color-text-primary;
  }

  &__section-header {
    font-weight: 500;
    font-size: toRem(12);
    color: $color-text-disabled;
    text-transform: uppercase;
  }

  &__section-link {
    margin-top: 8px;
    font-weight: 600;
    padding-inline: 8px;
    font-size: toRem(14);
    display: inline-flex;
    color: $color-primary;
  }

  &__section-row,
  &__attachment-section-row {
    display: flex;
    column-gap: 30px;
    margin-top: 15px;
    font-weight: 400;
    align-items: center;
    font-size: toRem(14);
    justify-content: space-between;
  }

  &__section-row-name {
    min-width: max-content;
    color: $color-text-secondary;
  }

  &__section-row-value {
    text-align: end;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $color-quaternary;
  }

  &__attachment-section-row-name {
    color: $color-blue-zodiac;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  &__attachment-section-row-value {
    display: flex;
    align-items: center;
    gap: 8px;

    .attachment-section-action-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
    }
  }

  &__total-section {
    display: flex;
    column-gap: 10px;
    border-radius: 6px;
    padding-block: 10px;
    align-items: center;
    margin-inline: 14px;
    flex-direction: column;
    justify-content: center;
    background-color: $color-main-background;

    & > div:first-child {
      font-size: toRem(16);
      color: $color-text-secondary;
    }

    & > div:last-child {
      margin-top: 6px;
      font-weight: 600;
      font-size: toRem(24);
      color: $color-primary;
    }
  }

  &__expanded-transfer {
    padding-inline: 23px;
    padding-block: 12px;
    margin-inline: 20px;
    margin-block-start: 32px;
    margin-block-end: 42px;
    background-color: $color-main-background;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__expanded-transfer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: toRem(14);
  }

  &__expanded-transfer-row-label {
    padding-inline-start: 14px;
  }

  &__expanded-transfer-row:not(:first-child) {
    .transaction-detail__expanded-transfer-row-label {
      position: relative;
      &::before {
        margin-inline-start: -12px;
        position: absolute;
        content: "+";
      }
    }
  }

  &__expanded-transfer-separator {
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    background-color: $color-text-secondary;
    width: 100%;
  }

  &__expanded-transfer-total-amount-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: toRem(15);
    color: $color-primary;
  }
}

[dir="rtl"] .details-sidebar__root--hidden {
  transform: translateX(-100%);
}
