@include build-translate-x(modal-form-slide-in, 0vw);

.modal-form {
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  transition: opacity 100ms ease-in-out, height 500ms ease-in-out,
    transform 300ms ease-in-out;
  transform: translate(100vw);
  display: none;

  &--shown {
    display: flex;
    animation: modal-form-slide-in 300ms ease-in forwards;
  }
}

.modal-form-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  &__label {
    font-weight: 500;
    font-size: toRem(14);
    color: $color-black;
  }

  &__input-element-wrapper {
    flex-basis: 300px;
    .input {
      width: 100%;
    }

    &--loading {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      .spinner {
        position: absolute;
      }
    }
  }

  &__input-element,
  .phone-number,
  .password-input {
    flex-basis: 300px;

    &--succeed {
      border: 1px solid $color-green-haze;
    }

    &--failed {
      border: 1px solid $color-brick-red;
    }
  }
}
