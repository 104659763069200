.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  &--push-down {
    margin-block-start: 20vh;
  }

  &--full {
    width: 100%;
    height: 85%;
  }

  &--delayed-250 {
    @include appearAfterMS(500, 250);
  }

  &--delayed-1000 {
    @include appearAfterMS(500, 1000);
  }
}

.spinner {
  @include full-spin(3s);
}
