@use "sass:color";

$employees-page-margin: 20px;

.employees-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $employees-page-margin;
  $height: calc(100% - ($breadcrumbs-height + ($employees-page-margin * 2)));
  height: $height;
  position: relative;
  overflow: hidden;

  .employees-card {
    @extend .card;
    overflow-y: auto;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    &--no-data {
      display: none;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline-start: 24px;
      padding-inline-end: 10px;
      margin-block: 16px;
    }

    .employees {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-block: 20px;
      margin-inline: 24px;
      $button-size: 24px;

      &__edit-button {
        width: $button-size;
        height: $button-size;
        margin-inline-end: 5px;
      }

      &__delete-button {
        width: $button-size;
        height: $button-size;
      }

      .action-cell {
        display: flex;
        justify-content: center;
      }

      &--no-data {
        display: none;
      }
    }

    .table-container {
      flex-grow: 1;
      overflow: auto;
      scrollbar-color: $color-mischka transparent;
      height: 200px;

      &__separator {
        border: none;
        height: 2px;
        background-color: $color-athens-gray-2;
        margin-block: 18px;
      }

      tr:hover {
        td {
          background-color: $color-aqua-haze;
        }
      }

      &--last-column-fixed {
        position: relative;

        th:last-child,
        td:last-child {
          box-shadow: 1px 0px 0px 0px $color-white;

          &::before {
            content: " ";
            height: 100%;
            position: absolute;
            top: 0;
            width: 15px;
            box-shadow: -3px 0 3px -3px color.change($color-black, $alpha: 0.6) inset;
            left: -15px;
          }
        }
      }

      &--first-column-fixed {
        position: relative;

        th:first-child,
        td:first-child {
          &::after {
            content: " ";
            height: 100%;
            position: absolute;
            top: 0;
            width: 15px;
            box-shadow: 3px 0 3px -3px color.change($color-black, $alpha: 0.6) inset;
            right: -15px;
          }
        }
      }
    }

    table {
      border-spacing: 0;
      border-collapse: separate;

      thead tr th {
        position: sticky;
        top: 0;
        background-color: $color-aqua-haze;

        &:last-child {
          position: sticky;
          right: 0;
          background-color: $color-aqua-haze;
          z-index: 1;
        }

        &:first-child {
          position: sticky;
          left: 0;
          background-color: $color-aqua-haze;
          z-index: 1;
        }
      }

      tbody tr {
        td:last-child {
          position: sticky;
          right: 0;
          background-color: $color-white;
        }
        td:first-child {
          position: sticky;
          left: 0;
          background-color: $color-white;
        }
      }

      min-width: 1200px;
      width: 100%;
    }
  }

  .no-employee-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-employee-found-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 2vh;
  }
}

@media (max-width: 575px) {
  .employees-page-btn {
    margin-top: 0;
  }
}

@media (max-width: 980px) {
  .employees #employees-table-container {
    thead tr th {
      &:first-child {
        left: auto;
      }
    }

    tbody tr {
      td:first-child {
        position: static;
      }
    }

    th:first-child,
    td:first-child {
      &::after {
        content: none;
      }
    }
  }
}

@media screen and (max-height: 730px) {
  .employees-page {
    max-height: auto;
    overflow: auto;

    .employees-card .table-container {
      height: 110px;
    }
  }
}
