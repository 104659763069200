table.summary-table {
  width: 100%;
  font-size: toRem(14);
  border-collapse: collapse;

  thead {
    position: sticky;
    top: 0;
  }

  thead tr {
    font-weight: 500;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: $color-text-primary;
    background-color: $color-main-background;

    th {
      text-align: start;
      font-weight: inherit;
      padding-block: 5px;

      strong {
        font-weight: 500;
      }

      span {
        font-weight: normal;
      }

      &:first-child {
        padding-inline: 10px;
        overflow-wrap: anywhere;
      }

      &:last-child {
        padding-inline-end: 10px;
      }
    }
  }

  thead tr,
  tbody tr {
    display: flex;
  }

  thead th:first-child,
  tbody td:first-child {
    flex-basis: 60%;
  }

  tbody {
    display: block;
    // overflow-y: auto;
    max-height: 250px;
    scrollbar-width: thin;
    scrollbar-color: $color-iron;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $color-iron;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $color-text-secondary;
    }
  }

  tbody tr {
    color: $color-text-secondary;

    td {
      padding-block-start: 5px;

      &:first-child {
        padding-inline-end: 10px;

        span:first-child {
          text-align: right;
          display: inline-block;
          margin-inline: 3px;
        }

        .summary-table__payee-name {
          word-break: break-all;
        }
      }

      &:last-child span {
        display: inline-block;
        margin-inline-start: 3px;
        color: $color-text-primary;
      }
    }
  }
}
