.document-view {
  margin: 8%;

  &__body {
    max-height: 100vh;
  }

  &__body-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__body-pdf {
    width: 80vw;
    height: 80vh;
    max-width: 1024px;
    border: none;
  }
}
