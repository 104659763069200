@use "sass:color";

.balance-page {
  padding: 20px;
}

.stacked-balance-chart-container {
  margin-block-start: 20px;
  margin-block-end: 50px;
  position: relative;

  &--loading {
    & > .balance-chart-container {
      transition: opacity 1000ms ease-in-out 1000ms;
      opacity: 0.3;
    }
  }

  .spinner-container {
    position: absolute;
  }
}

.extended-balance-chart {
  @extend .card;
  min-width: 560px;
  min-height: 590px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 24px;
    padding-inline-end: 10px;
    margin-block: 16px;
  }

  &__export-button {
    @extend .export-button;
  }
}
