.filter-bar {
  @extend .filter-cards;
  min-height: 52px;
  margin-inline: 24px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  &__label {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    color: $color-text-disabled;
    & > svg {
      color: $color-text-secondary;
    }
  }

  &__options {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;
    flex: 1;
    padding-block: 10px;
  }

  &__clear-btn {
    padding: 15px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

[dir="rtl"] {
  .filter-bar {
    &__clear-btn {
      border-top-right-radius: initial;
      border-bottom-right-radius: initial;

      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
}
