@use "sass:math";

.switch {
  $switch-width: 36px;
  $switch-padding: math.div($switch-width, 16);

  cursor: pointer;
  appearance: none;
  position: relative;
  width: $switch-width;
  display: inline-block;
  padding: $switch-padding;
  aspect-ratio: math.div(1.8, 1);
  background-color: $color-athens-gray-4;
  border-radius: calc(3.1 * $switch-width);
  transition: background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

  &::before {
    content: " ";
    aspect-ratio: 1;
    position: absolute;
    margin-block: auto;
    border-radius: 50%;
    top: $switch-padding;
    left: $switch-padding;
    background-color: $color-white;
    transition: left 150ms ease-in-out;
    height: calc(100% - $switch-padding * 2);
    box-shadow: 0px 1px 2px rgba($color-ebony, 0.06),
      0px 1px 3px rgba($color-ebony, 0.1);
  }

  &:hover {
    background-color: $color-athens-gray-2;
  }

  &:checked {
    background-color: $color-primary;

    &::before {
      left: $switch-width + $switch-padding - math.div($switch-width, 1.8);
    }
  }

  &:focus,
  &:focus-visible {
    outline: none;
    outline-offset: 0px;
    box-shadow: 0px 0px 0px 2px rgba($color-primary, 0.3);
  }

  &:disabled {
    cursor: auto;
    background-color: $color-athens-gray-4;
  }
}
