@use "sass:color";

.card {
  border-radius: 6px;
  box-shadow: 0px 2px 8px color.change($color-black, $alpha: 0.05);
  background-color: $color-white;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;
  margin-block: 16px;
}

.card-title {
  font-weight: 500;
  font-size: toRem(20);
  color: $color-text-secondary;
}

.filter-cards {
  background-color: $color-aqua-haze;
  border-radius: 6px;
}
