.link-reset {
  text-decoration: none;
}

.link {
  @extend .link-reset;
  position: relative;

  &--primary {
    color: $color-primary;
  }

  &--medium {
    font-size: toRem(14);
    font-weight: 500;
  }

  &:hover::after,
  &:focus::after {
    transform: scaleX(1);
  }

  &:focus-visible {
    outline: none;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 1.5px;
    background-color: currentColor;
    margin-bottom: -4px;
    transform: scaleX(0);
    @include link-transition();
  }
}
