.month-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  border: 1px solid $color-primary;
  padding: 15px;
  gap: 15px;
  width: 240px;
  background-color: $color-white;

  &__picker-wrapper {
    width: min-content;
    height: min-content;
  }

  &__input-wrapper {
    position: relative;
  }

  &__input {
    width: 100%;
  }

  &__input-caret {
    position: absolute;
    top: 0;
    transform: translateY(50%);
    right: toRem(9.6);
  }

  &__year-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 500;
    color: $color-text-secondary;
  }

  &__decrease-year-button,
  &__increase-year-button {
    appearance: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  &__decrease-year-icon {
    transform: rotate(0.25turn);
  }
  &__increase-year-icon {
    transform: rotate(-0.25turn);
  }

  &__separator {
    background-color: $color-primary;
    height: 1px;
    width: 100%;
  }

  &__months {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    width: calc(50px * 3 + 20px * 2);
  }

  &__month {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    appearance: none;
    background-color: $color-aqua-haze;
    color: $color-text-secondary;
    font-weight: 500;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
    width: 50px;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1;

    &:hover {
      background-color: $color-primary-lighter;
      color: $color-primary;
    }

    &--selected,
    &--selected:hover {
      background-color: $color-primary;
      transition-delay: 100ms, 100ms;
      color: $color-white;
      cursor: auto;
    }
  }
}

[dir="rtl"] {
  .month-picker {
    &__input-caret {
      left: toRem(9.6);
      right: unset;
    }

    &__decrease-year-icon {
      transform: rotate(-0.25turn);
    }
    &__increase-year-icon {
      transform: rotate(0.25turn);
    }
  }
}
