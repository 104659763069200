.balance-box {
  background-color: $color-white;
  padding: 17px;
  border-radius: 6px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  min-width: 280px;

  &__title--highlighted {
    color: $color-primary;
    font-weight: 500;
  }

  &__balance {
    margin-top: 14px;
    font-size: toRem(34);
    width: 100%;
  }

  &__title--highlighted,
  &__balance {
    @include ltrFontFamily();
  }
}
