@use "sass:color";

.dashboard-page {
  padding: 20px;
}

.account-boxes {
  display: flex;
  gap: 24px;
  min-height: 110px;
  flex-wrap: wrap;
  margin-block-end: 20px;

  &--empty {
    display: none;
  }
}

.chart-options {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  align-items: center;
  padding-inline: 24px;
  margin-block-end: 16px;

  .currency-options > .toggle-btn:not(:last-child),
  .timeframe-options > .toggle-btn:not(:last-child) {
    margin-inline-end: 4px;
  }
}

.balance-chart-container {
  margin-inline: auto;
  overflow: hidden;
}

.balance-chart {
  @extend .card;
  min-width: 560px;
  min-height: 480px;
  flex-direction: column;
  display: flex;
  flex: 1;

  &__header {
    @extend .card-header;
  }

  &__title {
    @extend .card-title;
  }

  &__link {
    @extend .link;
    @extend .link--primary;
    @extend .link--medium;
  }

  &__tooltip {
    @extend .chart-tooltip;
  }
}
