@mixin password-strength-styles {
  .tooltip--neutral {
    z-index: 2;
  }

  .password-strength {
    align-self: flex-end;
    position: relative;
    top: -14px;
  }

  .password-instruction-list {
    padding-inline-start: 10px;
    list-style-type: "  \2022   ";

    .checked {
      list-style-type: "  \2714  ";
    }
  }
}
