@use "sass:color";

$color-primary-0_2-transparent: color.change($color-primary, $alpha: 0.2);
$color-primary-0_3-transparent: color.change($color-primary, $alpha: 0.3);
$color-darker-shark-0_1-transparent: color.change(
  $color-darker-shark,
  $alpha: 0.1
);
$color-darker-shark-0_15-transparent: color.change(
  $color-darker-shark,
  $alpha: 0.15
);
$color-darker-shark-0_04-transparent: color.change(
  $color-darker-shark,
  $alpha: 0.04
);

$color-falu-red-0_2-transparent: color.change($color-falu-red, $alpha: 0.2);
$color-cardinal-0_4-transparent: color.change($color-cardinal, $alpha: 0.4);
$color-white-0_25-transparent: color.change($color-white, $alpha: 0.25);
$color-valencia-0_5-transparent: color.change($color-valencia, $alpha: 0.5);

$color-eucalyptus-0_1-transparent: color.change($color-eucalyptus, $alpha: 0.1);
$color-eucalyptus-0_3-transparent: color.change($color-eucalyptus, $alpha: 0.3);
$color-eucalyptus-0_04-transparent: color.change(
  $color-eucalyptus,
  $alpha: 0.04
);

$color-brick-red-0_1-transparent: color.change($color-brick-red, $alpha: 0.1);
$color-brick-red-0_3-transparent: color.change($color-brick-red, $alpha: 0.3);
$color-brick-red-0_04-transparent: color.change($color-brick-red, $alpha: 0.04);

.button {
  appearance: none;
  border: none;
  cursor: pointer;
  font-size: toRem(14);

  &--primary {
    background-color: $color-primary;
    color: $color-white;
    border: 1px solid $color-darker-shark-0_15-transparent;
    box-shadow: 0px 1px 0px 0px $color-darker-shark-0_1-transparent;

    &:focus {
      box-shadow: 0px 0px 0px 3px $color-primary-0_3-transparent;
    }

    &:hover {
      background-color: $color-primary-darker;
      box-shadow: 0px 1px 0px 0px $color-darker-shark-0_1-transparent,
        inset 0px 2px 0px 0px color.change($color-white, $alpha: 0.03);
    }

    &:active {
      background-color: $color-primary;
      box-shadow: inset 0px 2px 0px 0px
        color.change($color-parsley, $alpha: 0.2);
    }

    &:disabled {
      border: 1px solid $color-darker-shark-0_1-transparent;
      box-shadow: 0px 1px 0px 0px $color-darker-shark-0_1-transparent;
      background-color: $color-primary-lighter;
      cursor: auto;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &--secondary {
    background-color: $color-athens-gray-3;
    color: $color-text-primary;
    border: 1px solid $color-darker-shark-0_15-transparent;
    box-shadow: 0px 1px 0px 0px $color-darker-shark-0_04-transparent;

    &:focus {
      box-shadow: 0px 0px 0px 3px $color-primary-0_3-transparent;
    }

    &:hover {
      background-color: $color-athens-gray-6;
      box-shadow: 0px 1px 0px 0px $color-darker-shark-0_04-transparent;
    }

    &:active:not(:disabled) {
      background-color: $color-athens-gray-7;
      box-shadow: inset 0px 2.4px 0.3px 0px $color-darker-shark-0_15-transparent;
    }

    &:disabled {
      cursor: auto;
      color: $color-text-disabled;
      opacity: 0.5;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &--outline-secondary {
    color: $color-primary;
    background-color: $color-athens-gray-3;
    border: 1px solid $color-darker-shark-0_15-transparent;
    border-radius: 6px;
    font-weight: 500;
    padding-block: 5px;
    box-shadow: 0px 1px 0px 0px $color-darker-shark-0_04-transparent;
    @include link-transition();

    &:focus {
      box-shadow: 0px 0px 0px 3px $color-primary-0_3-transparent;
    }

    &:hover {
      color: $color-white;
      background-color: $color-primary-darker;
    }
    &:hover:not(:focus) {
      box-shadow: 0px 1px 0px 0px $color-darker-shark-0_1-transparent;
    }

    &:focus-visible {
      outline: none;
    }

    &:active {
      color: $color-white;
      background-color: $color-primary;
      box-shadow: 0px 1px 0px 0px color.change($color-blue-wale, $alpha: 0.2);
    }

    &:disabled {
      color: $color-primary-lighter;
      background-color: $color-athens-gray-3;
      box-shadow: 0px 1px 0px 0px $color-darker-shark-0_04-transparent;
    }
  }

  &--outline-success {
    color: $color-eucalyptus;
    background-color: $color-main-background;
    border: 1px solid $color-athens-gray-2;
    border-radius: 6px;
    font-weight: 500;
    padding-block: 5px;
    box-shadow: 0px 1px 0px 0px $color-eucalyptus-0_04-transparent;
    @include link-transition();

    &:focus {
      box-shadow: 0px 0px 0px 3px $color-eucalyptus-0_3-transparent;
    }

    &:hover {
      color: $color-white;
      background-color: $color-eucalyptus;
    }
    &:hover:not(:focus) {
      box-shadow: 0px 1px 0px 0px $color-eucalyptus-0_1-transparent;
    }

    &:focus-visible {
      outline: none;
    }

    &:active {
      color: $color-white;
      background-color: $color-eucalyptus;
      box-shadow: 0px 1px 0px 0px $color-eucalyptus-0_3-transparent;
    }

    &:disabled {
      color: $color-eucalyptus-0_3-transparent;
      background-color: $color-athens-gray-3;
      box-shadow: 0px 1px 0px 0px $color-eucalyptus-0_3-transparent;
    }
  }

  &--outline-danger {
    color: $color-brick-red;
    background-color: $color-main-background;
    border: 1px solid $color-athens-gray-2;
    border-radius: 6px;
    font-weight: 500;
    padding-block: 5px;
    box-shadow: 0px 1px 0px 0px $color-brick-red-0_04-transparent;
    @include link-transition();

    &:focus {
      box-shadow: 0px 0px 0px 3px $color-brick-red-0_3-transparent;
    }

    &:hover {
      color: $color-white;
      background-color: $color-brick-red;
    }
    &:hover:not(:focus) {
      box-shadow: 0px 1px 0px 0px $color-brick-red-0_1-transparent;
    }

    &:focus-visible {
      outline: none;
    }

    &:active {
      color: $color-white;
      background-color: $color-brick-red;
      box-shadow: 0px 1px 0px 0px $color-brick-red-0_3-transparent;
    }

    &:disabled {
      color: $color-brick-red-0_3-transparent;
      background-color: $color-athens-gray-3;
      box-shadow: 0px 1px 0px 0px $color-brick-red-0_3-transparent;
    }
  }

  &--invisible {
    background-color: transparent;
    border: 1px solid transparent;

    &:focus-visible {
      outline: none;
    }

    &:focus:not(:disabled) {
      background-color: $color-white;
      box-shadow: 0px 0px 0px 3px $color-primary-0_3-transparent;
    }

    &:hover:not(:disabled) {
      background-color: $color-white;
      box-shadow: 0px 1px 0px 0px color.change($color-black, $alpha: 0.15);
      border: 1px solid $color-athens-gray-2;
    }

    &:active:not(:disabled) {
      background-color: $color-white;
      box-shadow: inset 0px 1px 2px 0px color.change($color-black, $alpha: 0.45);
    }

    &:disabled {
      color: color.change($color-primary, $alpha: 0.2);
      cursor: auto;
    }
  }

  &--iconed {
    background-color: transparent;
    &:focus-visible {
      outline: 2px solid currentColor;
    }
  }

  &--loading {
    &,
    &:hover,
    &:disabled,
    &:focus,
    &:focus-visible,
    &:active {
      background-color: $color-text-secondary;
      color: $color-text-secondary;
      border: 1px solid $color-darker-shark-0_15-transparent;
    }
  }

  &--danger {
    background-color: $color-athens-gray-3;
    color: $color-cardinal;
    border: 1px solid $color-darker-shark-0_15-transparent;
    box-shadow: 0px 1px 0px 0px $color-darker-shark-0_04-transparent;

    &:focus {
      box-shadow: 0px 0px 0px 3px $color-cardinal-0_4-transparent;
    }

    &:hover {
      color: $color-white;
      background-color: $color-cardinal;
      box-shadow: 0px 1px 0px 0px $color-darker-shark-0_1-transparent;
    }

    &:active {
      color: $color-white;
      background-color: $color-cardinal;
      box-shadow: inset 0px 1px 0px $color-falu-red-0_2-transparent;
    }

    &:disabled {
      color: $color-valencia-0_5-transparent;
      box-shadow: 0px 1px 0px 0px $color-darker-shark-0_04-transparent inset 0px
        2px 0px 0px $color-white-0_25-transparent;
      background-color: $color-athens-gray-3;
      cursor: auto;
    }

    &:focus-visible {
      outline: none;
    }

    &-delete {
      background-color: $color-brick-red;
      color: $color-white;
    }
  }

  &--link {
    border-radius: 6px;
    background-color: transparent;
    color: $color-primary;
    border: 3px solid transparent;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: $color-tertiary;
      text-decoration: underline;
    }

    &:focus-visible {
      outline: none;
      border-color: $color-primary-0_2-transparent;
      text-decoration: none;
    }

    &:disabled {
      color: $color-primary-0_2-transparent;
    }
  }
}

.button.button-small {
  padding: 5px 12px;
  font-size: toRem(12);
}

.export-button {
  @extend .button;
  @extend .button--invisible;
  padding: 6px 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: $color-primary;
}
