@mixin ltrFontFamily {
  font-family: Inter, Vazir, sans-serif;
}

@mixin rtlFontFamily {
  font-family: Vazir, Inter, sans-serif;
}

@mixin end-with-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

* {
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  min-width: min(100vw, 100%);
  max-height: 100vh;
  min-height: 100vh;
  @include ltrFontFamily();
  font-size: toRem(16);
  color: $color-text-primary;
}

html[dir="rtl"],
html[dir="rtl"] body,
html[dir="rtl"] #root {
  @include rtlFontFamily();
}

#root > div,
#root > main {
  height: 100%;
}

#root {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: min-content 1fr;
  grid-template-areas: "navbar topbar" "navbar main";
}

.main-content-wrapper {
  overflow-y: auto;
  scrollbar-color: $color-mischka $color-athens-gray-1;
  background-color: $color-athens-gray-1;
  grid-area: main;
}

.anchor-style {
  &--clean {
    @extend .button;
    text-decoration: none;
    appearance: unset;
  }

  &--inactive {
    cursor: unset;
    appearance: unset;
    text-decoration: none;
  }
}

.plain-phone-number {
  unicode-bidi: plaintext;
  font-variant-numeric: tabular-nums;
  font-feature-settings: "tnum";
}

html[dir="rtl"] .plain-phone-number {
  text-align: end;
}

.amount--green {
  color: $color-forest-green;
}

.amount--red {
  color: $color-brick-red;
}
