@use "sass:color";

.login-page-container {
  grid-column: span 2;
  grid-row: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 0.3fr;
}

.instructions-section {
  margin-top: 90px;
  color: $color-text-primary;

  .instructions-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 460px;
    * {
      line-height: 1.2em;
    }

    .instructions-title {
      font-size: toRem(22);
      margin-top: 32px;
      font-weight: 600;
    }

    .instructions-message {
      margin-top: 12px;
      font-weight: 300;
    }

    ol.instructions {
      margin-top: 38px;
      padding-inline-start: 22px;
      list-style-type: decimal;
      li {
        &:not(:first-child) {
          margin-top: 8px;
        }
        > p {
          display: flex;
          align-items: center;
          > svg {
            margin-inline-start: 8px;
          }
        }
      }
    }
  }

  .logout-container {
    margin-block-start: 100px;
    margin-inline: auto;
    max-width: 460px;
    display: flex;
    align-items: center;
    gap: 18px;

    &__button {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: toRem(14);
      color: $color-primary;
      font-weight: 600;
      padding-block: 7px;
      padding-inline: 16px;
      transition: all 25ms ease-in-out;
      border-radius: 6px;

      &:hover {
        cursor: pointer;
        border: 1px solid $color-athens-gray-2;
        box-shadow: 0px 1px 0px color.change($color-black, $alpha: 0.15);
      }
    }

    &__logout-icon > path {
      stroke-width: 2.5px;
    }
  }
}

.qr-code-section {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  margin-top: 160px;
}

.login-qrcode-footer {
  margin-top: auto;
  margin-inline: auto;
  max-width: 450px;
  width: 100%;
  > p {
    font-weight: 300;
    margin-block: 16px;
    color: $color-text-disabled;
  }
}

@media (min-width: 1800px) {
  .login-page-container {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    column-gap: 10vw;
  }
}

@media (max-width: 980px) {
  .login-page-container {
    display: flex;
    flex-direction: column;
  }

  .instructions-section .logout-container {
    margin-block-start: 32px;
  }

  .qr-code-section {
    margin-top: 14vh;
    margin-bottom: 14vh;
  }

  .login-qrcode-footer {
    text-align: center;
  }
}
