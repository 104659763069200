.static-qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  position: relative;

  > .canvas {
    margin-top: 30px;
    width: 235px;
  }

  .static-qr-code-source {
    margin-top: 8px;
    color: $color-text-primary;
    font-size: toRem(18);
    font-weight: 500;
  }
}
