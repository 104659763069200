$color-toast-bg--error: $color-valencia;

:root {
  --toastify-toast-min-height: 52px;
}

.Toastify {
  &__toast {
    padding: 0;
    font-family: inherit;
    border-radius: 6px;
    margin-inline-end: 1rem;
  }

  &__toast-container {
    min-width: 68vw;
    max-width: 80vw;
    width: initial;

    &--top-left {
      top: 95px;
      left: 230px;
      @include common-transition;
    }
  }

  &__toast-body {
    margin: 0;
    padding: 0;

    & > div {
      height: 100%;
      width: 100%;
    }
  }
}

.navbar--collapsed + .main-content-wrapper + .Toastify {
  .Toastify__toast-container--top-left {
    left: 75px;
  }
}

.toast {
  $root: &;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border-radius: 6px;

  &__icon-container {
    width: 48px;
    height: 100%;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--error {
    #{$root}__icon-container {
      background-color: $color-toast-bg--error;
    }
  }

  &--success {
    #{$root}__icon-container {
      background-color: $color-eucalyptus;
    }
  }

  &__content {
    flex: 1;
    padding-block: 10px;
  }

  &__close-button {
    @extend .button;
    @extend .button--iconed;

    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-inline-end: 8px;

    &:hover {
      background-color: $color-athens-gray-1;
    }
    &:active {
      background-color: $color-athens-gray-2;
    }
  }

  &__auxilary-button {
    padding-inline: 12px;
    padding-block: 6px;
    border-radius: 6px;
  }
}
