// NOTE: This file is combination of a dozen of files
// it's content imported from the react-date-picker
// library. The purpose is to fully customize the component
// without the need to import their css files in the
// JS code and have full control over the appearance.
// For example rdp doesn't support RTL at all.
// Also note that, commented out empty rules are fine
// and left for sake of clarity and future change
// posibilities.

@use "sass:color";

// ***********************************
//                                   *
//       `calendar.scss`: START      *
//                                   *
// ***********************************

.rdrDateDisplay {
  display: flex;
  justify-content: space-between;
  margin: 0.833em;
}

.rdrMonths {
  display: flex;
}

.rdrMonthsVertical {
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  display: flex;
  flex-direction: row;
}

.rdrWeekDay {
  font-weight: 500;
  line-height: 2.667em;
  color: $color-text-secondary;
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
}

.rdrDays {
  display: flex;
  flex-wrap: wrap;
}

.rdrInfiniteMonths {
  overflow: auto;
}

// ***********************************
//                                   *
//        `calendar.scss`: END       *
//                                   *
// ***********************************

// ***********************************
//                                   *
//      `date-range.scss`: START     *
//                                   *
// ***********************************

.rdrDateRangeWrapper {
  user-select: none;
}

// ***********************************
//                                   *
//       `date-range.scss`: END      *
//                                   *
// ***********************************

// ***********************************
//                                   *
//       `day-cell.scss`: START      *
//                                   *
// ***********************************

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

// ***********************************
//                                   *
//        `day-cell.scss`: END       *
//                                   *
// ***********************************

// ***********************************
//                                   *
//  `date-range-picker.scss`: START  *
//                                   *
// ***********************************

.rdrDateRangePickerWrapper {
  display: inline-flex;
  user-select: none;
  border: 1px solid $color-primary;
  border-radius: 6px;
  overflow: hidden;
}

// ***********************************
//                                   *
//   `date-range-picker.scss`: END   *
//                                   *
// ***********************************

// ***********************************
//                                   *
//    `defined-ranges.scss`: START   *
//                                   *
// ***********************************

.rdrStaticRanges {
  display: flex;
  flex-direction: column;
}

// ***********************************
//                                   *
//     `defined-ranges.scss`: END    *
//                                   *
// ***********************************

// ***********************************
//                                   *
//        Custom Theme: START        *
//                                   *
// ***********************************

.rdrCalendarWrapper {
  box-sizing: border-box;
  background: $color-white;
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  color: $color-black;
  font-size: toRem(12);
}

// .rdrDateDisplayWrapper {
//     background-color: rgb(239, 242, 247);
// }

.rdrDateDisplayItem {
  border-radius: 4px;
  background-color: $color-white;
  box-shadow: inset 0px 1px 2px 0px
    color.change($color-darker-shark, $alpha: 0.075);
  border: 1px solid $color-athens-gray-2;
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;

  & + & {
    margin-left: 0.833em;
  }

  input {
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: $color-text-secondary;
    text-align: inherit;
    &:disabled {
      cursor: default;
    }
  }
}

.rdrDateDisplayItemActive {
  border-color: currentColor;
  input {
    color: $color-text-secondary;
  }
}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: toRem(14);
  color: $color-text-secondary;
  select {
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>")
      no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center;
    &:hover {
      background-color: rgba($color-black, 0.04);
    }

    option {
      background-color: rgba($color-black, 0.8);
      color: $color-white;
    }
  }
}

.rdrMonthPicker,
.rdrYearPicker {
  margin: 0 5px;
}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 1px solid $color-athens-gray-2;
  border-radius: 5px;
  background-color: transparent;
  &:hover {
    background: $color-athens-gray-2;
  }
  i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }
}

.rdrPprevButton {
  i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }
}

.rdrNextButton {
  i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }
}

.rdrWeekDays {
  display: flex;
  padding: 0 0.833em;
}

.rdrMonth {
  width: 20.5em;
  padding: 0 0.833em 1.666em 0.833em;
  .rdrWeekDays {
    padding: 0;
  }
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
  display: none;
}

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
  background: transparent;
  user-select: none;
  border: 0;
  padding: 0;
  line-height: 3em;
  height: 3em;
  text-align: center;
  color: $color-darker-shark;
  &:focus {
    outline: 0;
  }
}

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: $color-text-disabled;
    font-weight: 500;
  }
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 500;
  &:after {
    content: "";
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: $color-primary;
  }
}

.rdrDayToday:not(.rdrDayPassive) {
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected {
    & ~ .rdrDayNumber span:after {
      background: $color-white;
    }
  }
}

.rdrDay:not(.rdrDayPassive) {
  .rdrInRange {
    & ~ .rdrDayNumber {
      span {
        color: $color-primary;
      }
    }
  }

  .rdrStartEdge,
  .rdrEndEdge {
    & ~ .rdrDayNumber {
      span {
        color: $color-white;
      }
    }
  }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
  pointer-events: none;
}

.rdrInRange {
  background-color: color.change($color-primary, $alpha: 0.2);
  color: $color-primary;
}

.rdrStartEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  border-top-right-radius: initial;
  border-bottom-right-radius: initial;
  left: 2px;
}

.rdrEndEdge {
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
  right: 2px;
}

.rdrStartEdge.rdrEndEdge {
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
}

.rdrSelected {
  left: 2px;
  right: 2px;
  border-radius: 1.042em;
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {
  .rdrInRange,
  .rdrEndEdge {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }

  .rdrDayInPreview,
  .rdrDayEndPreview {
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {
  .rdrInRange,
  .rdrStartEdge {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }

  .rdrDayInPreview,
  .rdrDayStartPreview {
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
    border-right-width: 1px;
    right: 0px;
  }
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  pointer-events: none;
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
  right: 0px;
}

.rdrDefinedRangesWrapper {
  font-size: toRem(12);
  font-weight: 500;
  min-width: 150px;
  padding: 0.833em;
  background: $color-white;
  width: 100px;
  .rdrStaticRangeSelected {
    .rdrStaticRangeLabel {
      color: $color-text-primary;
    }
  }
}

.rdrStaticRange {
  @extend .button;
  @extend .button--invisible;
  font-size: inherit;
  border-radius: 6px;
}

.rdrStaticRangeLabel {
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
  color: $color-text-secondary;
  font-weight: 500;
}

.rdrInputRanges:not(:empty) {
  padding: 10px 0;
}

.rdrInputRange {
  display: flex;
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122);
  &:focus,
  &:hover {
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
  }
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper)
  .rdrDayHovered
  .rdrDayNumber:after {
  content: "";
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive {
  pointer-events: none;
  .rdrDayNumber span {
    color: $color-text-disabled;
    font-weight: 400;
    opacity: 0.5;
  }
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected,
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    display: none;
  }
}

.rdrDayDisabled {
  cursor: not-allowed;
  background-color: $color-aqua-haze;
  .rdrDayNumber span {
    color: $color-text-disabled;
  }
  .rdrInRange,
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrSelected,
  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    filter: grayscale(100%) opacity(60%);
  }
}

.rdrMonthName {
  text-align: left;
  font-weight: 600;
  color: $color-text-secondary;
  padding: 0.833em;
}

// ***********************************
//                                   *
//         Custom Theme: END         *
//                                   *
// ***********************************

// ***********************************
//                                   *
//       Component Stles: START      *
//                                   *
// ***********************************

.date-range-picker {
  position: relative;

  &__input {
    position: relative;
    min-width: 220px;
    width: 240px;

    input {
      min-height: 31px;
      display: block;
      width: 100%;
      padding: 6px 8px;
      border-radius: 6px;
    }

    &::after {
      position: absolute;
      content: " ";
      background-image: url("../../assets/icons/calendar.svg");
      background-size: cover;
      width: 16px;
      height: 16px;
      right: 8px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__picker-wrapper {
    z-index: 1;
    box-shadow: 0px 2px 8px 0px color.change($color-black, $alpha: 0.05);
  }
}

[dir="rtl"] {
  .rdrPprevButton {
    i {
      border-color: transparent transparent transparent rgb(52, 73, 94);
      transform: translate(3px, 0px);
    }
  }

  .rdrNextButton {
    i {
      margin: 0 7px 0 0;
      border-color: transparent rgb(52, 73, 94) transparent transparent;
      transform: translate(-3px, 0px);
    }
  }

  .rdrStartEdge {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    left: 0;
    right: 2px;
  }

  .rdrEndEdge {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    border-top-right-radius: initial;
    border-bottom-right-radius: initial;
    right: 0;
    left: 2px;
  }

  .rdrStartEdge.rdrEndEdge {
    border-radius: 1.042em;
    left: 2px;
    right: 2px;
  }

  .rdrDayStartPreview.rdrDayEndPreview {
    border-radius: 1.333em;
    border-width: 1px;
  }

  .rdrDayStartOfMonth,
  .rdrDayStartOfWeek {
    .rdrInRange,
    .rdrEndEdge {
      border-top-left-radius: initial;
      border-bottom-left-radius: initial;

      border-top-right-radius: 1.042em;
      border-bottom-right-radius: 1.042em;
      left: 0;
    }

    .rdrDayInPreview,
    .rdrDayEndPreview {
      border-top-left-radius: initial;
      border-bottom-left-radius: initial;
      border-left-width: 0;

      border-top-right-radius: 1.333em;
      border-bottom-right-radius: 1.333em;
      border-right-width: 1px;
    }
  }

  .rdrDayEndOfMonth,
  .rdrDayEndOfWeek {
    .rdrInRange {
      border-top-right-radius: initial;
      border-bottom-right-radius: initial;

      border-top-left-radius: 1.042em;
      border-bottom-left-radius: 1.042em;
      right: 0;
    }

    .rdrStartEdge {
      border-top-left-radius: 1.042em;
      border-bottom-left-radius: 1.042em;
      right: 0;
    }

    .rdrDayInPreview,
    .rdrDayStartPreview {
      border-top-right-radius: initial;
      border-bottom-right-radius: initial;
      border-right-width: 0;

      border-top-left-radius: 1.333em;
      border-bottom-left-radius: 1.333em;
      border-left-width: 1px;
    }
  }

  .rdrDayStartPreview {
    border-left-width: 0;
    border-right-width: 1px;
    border-top-left-radius: initial;
    border-bottom-left-radius: initial;
    border-top-right-radius: 1.333em;
    border-bottom-right-radius: 1.333em;
  }

  .rdrDayEndPreview {
    border-right-width: 0;
    border-left-width: 1px;

    border-top-right-radius: initial;
    border-bottom-right-radius: initial;

    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
  }

  .rdrStaticRangeLabel {
    text-align: right;
  }

  .date-range-picker {
    &__input {
      &::after {
        right: initial;
        left: 8px;
      }
    }
  }
}
