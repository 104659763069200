@use "sass:color";

.password-input {
  position: relative;

  &--small {
    height: 28px;
  }
  &--medium {
    height: 32px;
  }
  &--large {
    height: 40px;
  }

  &__toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6px;
    margin-block: auto;
    height: min-content;
    display: flex;
    align-items: center;
    border-radius: 50%;
    padding-inline: 3px;
    padding-block: 2px;
    color: $color-text-secondary;

    &:disabled {
      cursor: auto;
      color: $color-text-disabled;
    }
  }

  .input {
    width: 100%;
    padding-inline-end: toRem(30);
  }
}

[dir="rtl"] .password-input__toggle {
  right: unset;
  left: 6px;
  background-color: inherit;
}
