.password-strength {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;

  .progressbar-container {
    display: flex;
    flex: 1;
    gap: 4px;
  }

  &--weak {
    color: $color-mandy;
  }
  &--average {
    color: $color-porsche;
  }
  &--strong {
    color: $color-emerald;
  }

  &__progress {
    flex: 1;
    background-color: $color-athens-gray-2;
    height: 2px;
    border-radius: 1px;
    transition: background-color 100ms ease-in;
    max-width: 42px;

    &--filled {
      background-color: currentColor;
    }
  }

  &__label {
    color: $color-text-disabled;
    font-size: toRem(12);
    font-weight: 500;
    text-transform: lowercase;
    flex: 1;
    text-align: end;
  }
}
