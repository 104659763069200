.tooltip {
  font-size: toRem(12);
  border-radius: 6px;
  padding: 6px 8px;
  z-index: 1;

  &__arrow {
    position: absolute;
    top: -6.5px;
    border-top-left-radius: 1px;
    transform: rotate(45deg);
    left: 16px;
    width: 12px;
    height: 12px;
    background: inherit;
    border-top: inherit;
    border-left: inherit;
  }

  &--error {
    background-color: $color-chablis;
    color: $color-falu-red;
    border: 1px solid $color-froly;
  }

  &--neutral {
    background-color: $color-text-primary;
    color: $color-white;
    border: 1px solid $color-text-primary;
  }
}
