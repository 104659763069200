.selectable-accordion {
  &__prefix {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__list {
    padding-block: 10px;
  }

  &__list-item {
    display: flex;
    padding-inline: 10px;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &__list-item-label {
    cursor: pointer;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__list-item-content {
    display: flex;
    align-items: center;
    flex-basis: 90px;
  }
}
