@use "sass:color";

$team-details-margin: 20px;
$table-header-height: 96px;

.team-details-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $team-details-margin;
  $height: calc(100% - ($breadcrumbs-height + ($team-details-margin * 2)));
  height: $height;
  position: relative;
  overflow: hidden;

  &--hidden {
    display: none;
  }

  .team-details-card {
    @extend .card;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline-start: 24px;
      padding-inline-end: 10px;
      margin-block: 16px;
    }

    .search-field {
      margin-bottom: 10px;
    }

    &--no-data {
      display: none;
    }

    .team-members {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-block: 20px;
      margin-inline: 24px;
      $button-size: 24px;

      &--no-data {
        display: none;
      }

      &__delete-button {
        width: $button-size;
        height: $button-size;
        margin-inline-start: 15px;
      }
    }

    .table-container {
      flex-grow: 1;
      overflow: auto;
      scrollbar-color: $color-mischka transparent;
      height: 200px;

      &__separator {
        border: none;
        height: 2px;
        background-color: $color-athens-gray-2;
        margin-block: 18px;
      }

      &--last-column-fixed {
        position: relative;

        th:last-child,
        td:last-child {
          box-shadow: 1px 0px 0px 0px $color-white;

          &::before {
            content: " ";
            height: 100%;
            position: absolute;
            top: 0;
            width: 15px;
            box-shadow: -3px 0 3px -3px color.change($color-black, $alpha: 0.6) inset;
            left: -15px;
          }
        }
      }

      &--first-column-fixed {
        position: relative;

        th:first-child,
        td:first-child {
          &::after {
            content: " ";
            height: 100%;
            position: absolute;
            top: 0;
            width: 15px;
            box-shadow: 3px 0 3px -3px color.change($color-black, $alpha: 0.6) inset;
            right: -15px;
          }
        }
      }

      tr:hover {
        td {
          background-color: $color-aqua-haze;
        }
      }
    }

    table {
      border-spacing: 0;
      border-collapse: separate;

      thead tr th {
        position: sticky;
        top: 0;
        background-color: $color-aqua-haze;

        &:last-child {
          position: sticky;
          right: 0;
          background-color: $color-aqua-haze;
          z-index: 1;
        }

        &:first-child {
          position: sticky;
          left: 0;
          background-color: $color-aqua-haze;
          z-index: 1;
        }
      }

      tbody tr {
        td:last-child {
          position: sticky;
          right: 0;
          background-color: $color-white;
        }
        td:first-child {
          position: sticky;
          left: 0;
          background-color: $color-white;
        }
      }

      min-width: 1180px;
      width: 100%;
    }

    .no-team-member-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-block: 2vh;
    }

    .spinner-container {
      position: absolute;
      height: calc(100% - $table-header-height);
      bottom: 0;
    }
  }
}

@media (max-width: 980px) {
  .team-members #team-members-table-container {
    thead tr th {
      &:first-child {
        left: auto;
      }
    }

    tbody tr {
      td:first-child {
        position: static;
      }
    }

    th:first-child,
    td:first-child {
      &::after {
        content: none;
      }
    }
  }
}

@media screen and (max-height: 730px) {
  .team-details-page {
    max-height: auto;
    overflow: auto;

    .team-details-card .table-container {
      height: 110px;
    }
  }
}
