@use "sass:color";

.employee-information-step-process {
  width: 600px;

  .modal-form-control {
    padding-inline: 60px;
  }

  .select-box__handle {
    min-width: 300px;
  }

  &__iban-input {
    position: relative;
    .spinner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    width: 300px;
    display: flex;

    &--loading .input {
      background-color: $color-text-disabled;
    }
  }

  .date-picker {
    width: 300px;
  }
}

.add-employee-process-container,
.edit-employee-process-container {
  max-width: 642px;
}

.add-employee-authorization-step,
.edit-employee-authorization-step {
  width: 600px;
}
