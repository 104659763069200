.changing-terminal-password-set-password-step {
  @include password-strength-styles();
}

.changing-terminal-password-set-password-step,
.changing-terminal-password-authorization-step {
  width: 600px;
}

.changing-terminal-password-process-container {
  .authorize-pin-container {
    width: 600px;

    #authorize-pin-form.pin {
      padding-block-start: 30px;
      padding-block-end: 10px;
      height: unset;
    }

    .flash-message.flash-message--shown {
      margin-block-start: 20px;
      margin-block-end: 0;
    }
  }
}
