@include build-slide-animation(slide-from-left, translateX(0));
@include build-slide-animation(slide-to-right, translateX(50vw));

.auth-presentation {
  grid-column: span 2;
  grid-row: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  .content-section {
    grid-row: span 2;
    display: grid;
    grid-template-rows: 150px 1fr;

    &__logo {
      align-self: end;
    }

    &__footer {
      align-self: start;
      color: $color-text-disabled;
    }

    &__logo,
    &__footer {
      margin-inline: 20%;
    }
  }

  .presentation {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-selago;
    overflow: hidden;
    position: relative;
    grid-row: span 2;
    padding-block-start: 6vh;

    &__slides {
      height: 570px;
      position: relative;
      max-width: 45rem;
      width: 45rem;
    }

    &__slide {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      flex-direction: column;
      align-items: center;
      transform: translate(-50vw);
      max-width: 100%;
      text-align: center;

      &--active {
        display: flex;
        transform: translateX(-50vw);
        animation: slide-from-left 1000ms forwards;
      }

      &--active-before {
        display: flex;
        transform: translateX(0);
        animation: slide-to-right 1000ms forwards;
      }

      &-title {
        margin-top: 36px;
        max-width: 520px;
        line-height: 150%;
        font-size: toRem(24);
      }

      &-description {
        margin-top: 10px;
        max-width: 460px;
        font-size: toRem(16);
      }

      &-title,
      &-description {
        margin-inline: 20px;
      }

      &-image {
        max-width: 100%;
      }
    }

    &__nav {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 12px;
    }

    &__nav-btn {
      appearance: none;
      border: none;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0 6px;
      background-color: $color-primary-lighter;
      transition: all 300ms ease-in-out;
      cursor: pointer;

      &--active {
        width: 12px;
        height: 12px;
        transform: scale(1.166);
        background-color: $color-primary;
      }
    }
  }
}

@media (max-width: 1200px) {
  .auth-presentation {
    .content-section {
      &__logo,
      &__footer {
        margin-inline: 10%;
      }
    }
  }
}

@media (max-width: 872px) {
  .auth-presentation {
    .content-section {
      grid-column: span 2;
      justify-items: center;

      &__logo,
      &__footer {
        margin-inline: unset;
      }
    }

    .presentation {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .auth-presentation {
    .content-section {
      grid-template-rows: 100px 1fr 100px;
    }
  }
}
