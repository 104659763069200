@use "sass:color";

.progress-bar-container {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: color.change($color-primary, $alpha: 0.2);
  overflow: hidden;

  &__bar {
    width: 0;
    height: 100%;
    max-width: 100%;
    border-radius: inherit;
    background-color: $color-primary;
    transition: all 250ms ease-in;
  }
}
