.details-sidebar__body {
  .details-sidebar-body__table-container {
    margin-top: 30px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $color-iron;

    &--many {
      max-height: 200px;

      .summary-table tbody {
        max-height: unset;
        overflow-y: unset;
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $color-iron;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $color-text-secondary;
    }
  }

  .details-sidebar-body__section {
    margin-bottom: 35px;
  }

  .summary-table {
    margin-bottom: 30px;

    tbody {
      max-height: 200px;
    }
  }
}
