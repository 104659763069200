@use "sass:color";

.date-picker {
  position: relative;

  &__input {
    position: relative;
    min-width: 220px;
    width: 100%;

    input {
      min-height: 31px;
      display: block;
      width: 100%;
      padding: 6px 8px;
      border-radius: 6px;
      cursor: pointer;
      height: inherit;
      padding-inline-end: 1.5rem;
    }

    &::after {
      content: " ";
      position: absolute;
      background-image: url("../../assets/icons/calendar.svg");
      background-size: cover;
      width: 16px;
      height: 16px;
      right: 8px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__picker-wrapper {
    z-index: 2;
    position: absolute;
    box-shadow: 0px 2px 8px 0px color.change($color-black, $alpha: 0.05);
  }

  .rdrCalendarWrapper {
    position: absolute;
    border-radius: 6px;
    border: 1px solid $color-primary;
    box-shadow: 0 2px 8px 0 color.change($color-black, $alpha: 0.05);

    .rdrNextPrevButton {
      border: none;
    }

    .rdrMonthInput {
      display: flex;
      column-gap: 8px;
      margin-inline: 8px;
      margin-bottom: 8px;
      height: 30px;

      &__input {
        max-width: 140px;
        padding-inline: 8px;
      }

      &__button {
        flex-grow: 1;
        border-radius: 6px;
      }
    }

    .rdrMonthApply {
      display: flex;
      margin-top: 15px;
      column-gap: 15px;

      &__clear {
        flex-grow: 1;
      }

      &__seprator {
        color: $color-primary;
      }

      &__apply {
        border-radius: 6px;
        flex-grow: 1;
      }
    }

    .rdrDay:not(.rdrDayPassive) .rdrSelected {
      & ~ .rdrDayNumber span {
        color: $color-white;

        &:after {
          color: $color-white;
        }
      }
    }
  }
}

[dir="rtl"] {
  .date-picker {
    &__input {
      &::after {
        right: initial;
        left: 8px;
      }
    }
  }
}
