.pin {
  display: flex;
  justify-content: center;
  width: 100%;

  &--small {
    height: 70px;
    gap: 10px;
  }

  &--medium {
    height: 78px;
    gap: 20px;
  }

  &--large {
    height: 86px;
    gap: 30px;
  }

  .pin__input {
    text-align: center;
    min-width: 10px;
    border: none;
    border-radius: 6px;
    background-color: rgba($color-athens-gray-2, 0.6);
    color: $color-primary;
    cursor: pointer;
    caret-color: transparent;
    user-select: none;

    &--content-shown {
      font-size: toRem(50);
    }

    &--content-hidden {
      font-family: monospace;
      font-size: 500%;
      padding-block-end: 8px;
    }

    &::selection {
      display: none;
    }

    &:hover {
      background-color: rgba($color-athens-gray-2, 0.5);
    }

    &:focus {
      outline: 3px solid $color-periwinkle;
      background-color: rgba($color-athens-gray-2, 0.5);
    }

    &:focus:disabled {
      outline: none;
    }

    &:disabled {
      background-color: $color-athens-gray-2, 1;
      cursor: default;
    }
  }

  .pin__separator {
    display: inline-block;
    border-radius: 30%;
    height: 2px;
    background-color: black;
    margin-top: 36px;

    &--short {
      width: 165px;
    }

    &--long {
      width: 200px;
    }
  }

  @-moz-document url-prefix() {
    .pin__input--content-hidden {
      font-size: 350%;
    }
  }
}
