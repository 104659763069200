@use "sass:color";

.not-allowed-users {
  max-width: 450px;
  padding-block-start: 10vh;
  margin-inline-start: 20%;
  margin-inline-end: 5%;
  display: flex;
  flex-direction: column;

  &__title {
    margin-block-start: 10px;
    margin-block-end: 4vh;

    &--invisible {
      visibility: hidden;
    }
  }

  &__message {
    margin-block: 10px;
    text-align: justify;
    line-height: 1.4;
  }

  .logout-container {
    margin-block-start: 15vh;
    max-width: 460px;
    display: flex;
    align-items: center;
    gap: 18px;

    &__button {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: toRem(14);
      color: $color-primary;
      font-weight: 600;
      padding-block: 7px;
      padding-inline: 16px;
      transition: all 25ms ease-in-out;
      border-radius: 6px;

      &:hover {
        cursor: pointer;
        border: 1px solid $color-athens-gray-2;
        box-shadow: 0px 1px 0px color.change($color-black, $alpha: 0.15);
      }
    }

    &__logout-icon > path {
      stroke-width: 2.5px;
    }
  }
}

@media (max-width: 1200px) {
  .not-allowed-users {
    margin-inline-start: 10%;
  }
}

//   FIXME review these and delete unnecessary ones
@media (max-width: 872px), screen and (orientation: portrait) {
  .not-allowed-users {
    margin-inline: 6vw;
    align-items: center;
  }
}
