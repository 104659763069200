.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  overflow-x: auto;
  padding: 4px;

  &__entries {
    color: $color-text-secondary;
    font-size: toRem(14);
  }

  .page-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 0;

    &__next-page,
    &__prev-page {
      @extend .button;
      @extend .button--invisible;

      color: $color-primary;
      font-weight: 500;
      display: flex;
      gap: 6px;
      align-items: center;
      min-height: 32px;
      border-radius: 6px;
      padding-inline: 4px;

      & > svg {
        stroke-width: 2px;
      }

      &:disabled {
        color: $color-text-disabled;
      }
    }

    &__prev-page > svg {
      transform: rotate(0.25turn);
    }
    &__next-page > svg {
      transform: rotate(-0.25turn);
    }

    &__numbered-page {
      @extend .button;
      color: $color-text-primary;
      width: 32px;
      height: 32px;
      border-radius: 6px;
      background-color: transparent;

      &:focus-visible {
        outline: none;
      }

      &:focus,
      &:hover {
        border: 2px solid $color-athens-gray-2;
      }

      &--current:disabled {
        cursor: auto;
        color: $color-white;
        background-color: $color-primary;
        border: none;
        font-weight: 500;
      }
    }
  }
}

[dir="rtl"] {
  .pagination .page-controls {
    &__prev-page > svg {
      transform: rotate(-0.25turn);
    }
    &__next-page > svg {
      transform: rotate(0.25turn);
    }
  }
}
