@use "sass:color";

.input {
  appearance: none;
  cursor: pointer;
  box-shadow: inset 0px 1px 2px 0px
    color.change($color-darker-shark, $alpha: 0.075);
  border: 1px solid $color-athens-gray-2;
  border-radius: 6px;
  font-size: toRem(14);

  &--small {
    height: 28px;
    padding-inline: 4px;
  }

  &--medium {
    height: 32px;
    padding-inline: 6px;
  }

  &--large {
    height: 40px;
    padding-inline: 8px;
  }

  &--loading:disabled {
    background-color: $color-text-disabled;
  }

  &:focus {
    border: 1px solid $color-primary;
    box-shadow: 0px 0px 0px 3px color.change($color-science-blue, $alpha: 0.3);
  }

  &:disabled {
    background-color: $color-aqua-haze;
    cursor: auto;
    color: $color-text-disabled;
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: $color-text-disabled;
    opacity: 1;
  }

  &--error {
    border: 1px solid $color-cardinal;

    &:focus {
      box-shadow: unset;
      border: 1px solid $color-cardinal;
    }
  }

  &--success {
    border: 1px solid $color-eucalyptus;

    &:focus {
      box-shadow: unset;
      border: 1px solid $color-eucalyptus;
    }
  }

  // NOTE hide default eye icon set by browsers
  &[type="password"]::-ms-reveal {
    display: none;
  }
}

.checkbox {
  appearance: none;
  border: 2px solid $color-text-disabled;
  background-color: $color-white;
  width: 20px;
  height: 20px;
  cursor: pointer;
  box-shadow: inset 0px 1px 2px 0px
    color.change($color-darker-shark, $alpha: 0.075);
  border-radius: 6px;
  font-size: toRem(14);

  &:focus-visible,
  &:focus {
    outline: none;
    box-shadow: 0 0 0px 3px $color-primary-lighter;
    border: 2px solid $color-primary;
  }

  &:checked {
    border-color: $color-primary;
    background-color: $color-primary;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.72954 4.61607C1.35181 4.21316 0.718971 4.19275 0.316059 4.57048C-0.0868534 4.94821 -0.107267 5.58104 0.270463 5.98395L1.72954 4.61607ZM3.25 7.70001L2.52046 8.38395C2.71474 8.59118 2.98797 8.70601 3.27195 8.69977C3.55594 8.69354 3.82387 8.56682 4.00886 8.35127L3.25 7.70001ZM9.75886 1.65126C10.1185 1.23215 10.0704 0.600822 9.65126 0.241143C9.23215 -0.118537 8.60082 -0.0703622 8.24114 0.348743L9.75886 1.65126ZM0.270463 5.98395L2.52046 8.38395L3.97954 7.01607L1.72954 4.61607L0.270463 5.98395ZM4.00886 8.35127L9.75886 1.65126L8.24114 0.348743L2.49114 7.04876L4.00886 8.35127Z' fill='white'/%3E%3C/svg%3E%0A");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px;
  }

  &:disabled:checked {
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.72954 4.61607C1.35181 4.21316 0.718971 4.19275 0.316059 4.57048C-0.0868534 4.94821 -0.107267 5.58104 0.270463 5.98395L1.72954 4.61607ZM3.25 7.70001L2.52046 8.38395C2.71474 8.59118 2.98797 8.70601 3.27195 8.69977C3.55594 8.69354 3.82387 8.56682 4.00886 8.35127L3.25 7.70001ZM9.75886 1.65126C10.1185 1.23215 10.0704 0.600822 9.65126 0.241143C9.23215 -0.118537 8.60082 -0.0703622 8.24114 0.348743L9.75886 1.65126ZM0.270463 5.98395L2.52046 8.38395L3.97954 7.01607L1.72954 4.61607L0.270463 5.98395ZM4.00886 8.35127L9.75886 1.65126L8.24114 0.348743L2.49114 7.04876L4.00886 8.35127Z' fill='%23FAFBFC'/%3E%3C/svg%3E%0A");
    background-color: $color-athens-gray-2;
    border: 2px solid $color-athens-gray-2;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 10px;
  }

  &:disabled {
    background-color: $color-athens-gray-2;
    border: 2px solid $color-regent-gray;
  }
}
