@use "sass:color";

$color-topbar-bg: $color-white;
$color-user-info-bg: $color-aqua-haze;
$color-user-info-border-x: $color-white-lilac;
$topbar-height: 60px;

.topbar {
  grid-area: topbar;
  height: $topbar-height;
  background-color: $color-topbar-bg;
  z-index: 2;
  box-shadow: 0px 2px 4px color.change($color-black, $alpha: 0.05);
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 20px;

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    .menu-icon {
      color: $color-text-primary;
    }

    .bell-icon {
      color: $color-regent-gray;
    }

    .qrcode-icon {
      color: $color-shuttle-gray;
    }
  }

  .user-box {
    display: flex;
    align-items: stretch;
    gap: 20px;

    &__icons {
      display: flex;
      gap: 12px;
    }
  }

  .user-info-box {
    position: relative;
    flex-grow: 1;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $color-user-info-bg;
    min-width: 200px;
    max-width: 200px;
    width: 100%;
    padding-inline: 16px;
    height: 100%;
    border-left: 1px solid $color-user-info-border-x;
    border-right: 1px solid $color-user-info-border-x;
    text-align: start;

    &__name {
      display: block;
      color: $color-text-secondary;
      font-size: toRem(16);
      font-weight: 500;
      letter-spacing: 0.05em;
      max-width: 135px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__role {
      display: block;
      font-size: toRem(12);
      font-weight: normal;
      color: $color-text-secondary;
      max-width: 135px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .user-avatar-wrapper {
      width: 32px;
      height: 32px;
      border-radius: 50%;

      img {
        object-fit: contain;
      }
    }
  }

  .listbox {
    min-width: 200px;
    width: 100%;
    top: $topbar-height + 2px;
    right: 0px;
    position: absolute;
    background-color: $color-white;
    border: 1px solid $color-athens-gray-2;
    border-radius: 6px;
    box-shadow: 0px 2px 8px color.change($color-black, $alpha: 0.05);
    color: $color-text-secondary;
    display: none;
    overflow: hidden;
    list-style-type: none;

    &--opened {
      display: block;
      animation: fade-in 150ms forwards ease-in-out;
    }

    a {
      color: inherit;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 8px 16px;
      text-decoration: none;

      &:hover,
      &:focus {
        color: $color-text-primary;
        background-color: $color-aqua-haze;
      }
    }
  }
}

html[dir="rtl"] {
  .topbar {
    .listbox {
      right: unset;
      left: 0;
    }

    .user-info__name {
      letter-spacing: unset;
    }
  }
}
