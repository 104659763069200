$color-link-normal-non-active: $color-mischka;
$color-link-active: $color-white;
$color-link-hover: $color-melrose;
$color-link-disabled: $color-regent-gray;
$color-navbar-bg: $color-rhino;
$color-navbar-bg-hover: $color-rhino-light;

$navlink-padding-y: 7px;
$navbar-collapsed-width: 65px;
$navlinks-margin-x: 24px;
$navlink-title-text-padding-x: 14px;
$navlink-icon-width: 18px;

$navlink-left-padding: $navlinks-margin-x + $navlink-title-text-padding-x +
  $navlink-icon-width;

.navbar {
  grid-area: navbar;
  background-color: $color-navbar-bg;
  color: $color-link-disabled;
  width: 220px;
  @include common-transition;

  .logo-wrapper {
    padding: 15px;
    display: flex;
    justify-content: center;

    a {
      overflow: hidden;

      .app-logo {
        color: $color-link-normal-non-active;
      }

      &:hover,
      &:focus-visible {
        .app-logo {
          color: $color-aqua-haze;
          @include common-transition;
        }
      }
    }
  }

  .navigation {
    margin-top: 26px;
  }

  .navigation-title {
    text-transform: uppercase;
    padding: 0;
    margin: 0 $navlinks-margin-x;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: toRem(12);
    color: $color-link-disabled;
    display: block;
    @include common-transition;
  }

  .navlinks {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  .navlink-icon {
    @include common-transition;
  }

  .navlink-group {
    font-weight: 300;
    position: relative;
    @include common-transition;

    &--active {
      .navlink-group-title {
        color: $color-link-active;
      }
    }

    &--open {
      & > .navlink-group-wrapper {
        max-height: 200px;
        opacity: 1;
        pointer-events: initial;
      }

      & .navlink-group-arrow {
        transform: rotate(0);
      }
    }
  }

  .navlink-group-title {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    grid-template-areas: "link_icon text arrow_icon";
    grid-template-rows: min-content 1fr;
    align-items: center;
    width: 100%;
    text-decoration: none;
    padding: $navlink-padding-y 24px;
    cursor: pointer;
    font-weight: 400;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
    border: none;
    @include common-transition;

    &:hover,
    &:focus-visible {
      color: $color-link-active;
      outline: none;
    }
  }

  .navlink-group-title-text {
    @include common-transition;
    font-size: toRem(15);
    padding-inline-start: $navlink-title-text-padding-x;
    justify-self: start;
  }

  .navlink-group-arrow {
    justify-self: flex-end;
    transform: rotate(180deg);
    @include common-transition;
  }

  .navlink-group-wrapper {
    max-height: 0px;
    opacity: 0;
    pointer-events: none;
    padding-inline-start: $navlink-left-padding;
    @include common-transition;
  }

  .navlink {
    display: block;
    padding: $navlink-padding-y 0;
    color: inherit;
    text-decoration: none;
    @include link-transition;

    &:hover,
    &:focus {
      color: $color-link-hover;
    }

    &--active {
      color: $color-link-active;
    }

    &--disabled,
    &--disabled:hover,
    &--disabled:focus {
      color: $color-link-disabled;
      cursor: not-allowed;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &--collapsed {
    width: $navbar-collapsed-width;

    .navlink-group-title-text,
    .navlink-group-arrow,
    .navlink {
      opacity: 0;
      max-width: 0;
    }

    .navigation-title {
      margin-inline: 0;
      text-align: center;
    }

    .navlink-group {
      .navlink-group-title {
        max-width: 100%;
        overflow: hidden;
      }

      &:hover,
      .navlink-group-title:hover {
        background: $color-navbar-bg-hover;
      }

      &:hover .navlink-group-wrapper,
      &:focus-within .navlink-group-wrapper,
      .navlink-group-title:hover + .navlink-group-wrapper {
        max-width: 300px;
        max-height: 300px;
        opacity: 1;
        display: flex;
        flex-direction: column;
        z-index: 2;
      }
    }

    .navlink-group-wrapper {
      max-width: 0;
      max-height: 0;
      overflow: hidden;
      position: absolute;
      display: none;
      top: 0;
      padding-inline-start: 0;
      left: $navbar-collapsed-width;
      background: $color-navbar-bg-hover;
      pointer-events: initial;

      .navlink {
        max-width: 300px;
        width: max-content;
        padding: 8px 12px;
        opacity: 1;
      }
    }
  }
}

html[dir="rtl"] {
  .navbar {
    &--collapsed {
      .navlink-group {
        .navlink-group-wrapper {
          left: unset;
          right: $navbar-collapsed-width;
        }
      }
    }
  }
  .navigation-title {
    font-size: toRem(13);
  }
}
