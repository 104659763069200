.add-team-information-step,
.edit-team-information-step {
  .modal-form-control {
    padding-inline: 25px;
  }

  &__search-employee-input {
    width: 100%;
  }

  &__employees-popover {
    border-radius: 6px;
    border: 1px solid $color-primary;
    background-color: $color-white;
  }

  &__filtered-employees-list {
    list-style-type: none;
    border-radius: 6px;
    max-height: 250px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ced1d9 transparent;

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
      border-radius: 6px;
      margin-inline-end: 2px;
    }
  }

  &__filtered-employees-list-item-button {
    color: $color-text-secondary;
    width: 100%;
    padding-block: 10px;
    padding-inline-start: 10px;
    padding-inline-end: 20px;
    text-align: start;
    background-color: transparent;

    &:focus-visible {
      outline: none;
    }

    &:focus-visible,
    &:hover,
    &:focus {
      color: $color-text-primary;
      background-color: $color-athens-gray-4;
    }
  }

  &__filtered-employees-list-item:first-child
    > &__filtered-employees-list-item-button {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &__filtered-employees-list-item:last-child
    > &__filtered-employees-list-item-button {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &__added-employees-list-container {
    flex-direction: column;
  }

  &__added-employees-list {
    padding-block: 6px;
    border: 1px solid $color-main-border;
    border-radius: 6px;
    height: 300px;
    width: 300px;
    align-self: flex-end;
    list-style-type: none;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ced1d9 transparent;

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
      border-radius: 6px;
      margin-inline-end: 2px;
    }

    &:focus-visible {
      outline: 1px solid $color-primary;
    }
  }

  &__added-employees-list-number {
    width: 295px;
    align-self: flex-end;
  }

  &__added-employees-list-item {
    color: $color-text-secondary;
    padding-inline: 18px;
    padding-block: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__added-employees-list-item-button {
    width: 22px;
    height: 22px;
    color: $color-text-secondary;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__added-employees-list-item-name {
    overflow-wrap: anywhere;
  }
}

.add-team-information-step,
.add-team-authorization-step,
.edit-team-information-step,
.edit-team-authorization-step {
  width: 500px;
}

.edit-team-process-container,
.add-team-process-container {
  .authorize-pin-container {
    width: 600px;

    #authorize-pin-form.pin {
      padding-block-start: 30px;
      padding-block-end: 10px;
      height: unset;
    }

    .flash-message.flash-message--shown {
      margin-block-start: 20px;
      margin-block-end: 0;
    }
  }
}
