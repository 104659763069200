.shift-money {
  $root: &;

  &-transfer-step {
    &__transfer-section {
      display: flex;
      flex-direction: column;
      padding: 20px;
      border: 1px solid $color-primary;
      border-radius: 6px;
      font-weight: 500;
      font-size: toRem(12);
      color: $color-text-secondary;

      &-record {
        color: $color-text-primary;
        margin-block: 10px;
      }

      &-center {
        display: flex;
        justify-content: center;

        &--hidden {
          visibility: hidden;
        }

        &--fixed-size {
          justify-content: flex-start;
          max-width: 240px;
          width: 100%;
          align-self: flex-end;
        }
      }

      .select-box__list-wrapper {
        z-index: 2;
      }
    }

    .select-box__handle {
      min-width: 240px;
    }

    &__amount {
      cursor: unset;
      padding: 8px;
      width: 240px;
    }

    &__seprator {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-block: 10px;
    }
  }

  &-authorization-and-confirmation-step {
    display: flex;
    flex-direction: row;
    width: 650px;

    .shift-money-confirmation {
      color: $color-text-secondary;
      flex-grow: 1;
      font-size: toRem(12);

      &__heading {
        letter-spacing: 0.1em;
        margin-block-start: 10px;
        margin-block-end: 5px;
        text-transform: uppercase;
        font-weight: 700;
      }

      &__transfer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-weight: 500;
        padding-inline: 10px;
        margin-block-start: 50px;

        div div:last-child {
          font-size: toRem(16);
          margin-block: 5px;
          color: $color-text-primary;
        }

        svg {
          color: $color-primary;
        }

        &-source,
        &-target {
          overflow-wrap: anywhere;
        }
      }

      &__money {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        padding-block: 10px;
        margin-block-start: 40px;
        background-color: $color-main-background;

        div {
          font-size: toRem(22);
          font-weight: 900;
          margin-bottom: 5px;
          color: $color-text-primary;
        }
      }

      &__date {
        margin-block-start: 40px;
        text-align: center;
        font-size: toRem(14);
      }
    }
  }

  &-confirmation-step {
    width: 510px;

    .shift-money-confirmation {
      &__transfer div {
        flex-basis: 50%;
        text-align: center;
      }
    }
  }
}

.shift-money-process-container {
  .authorize-pin-container {
    #authorize-pin-form.pin {
      padding-block-start: 30px;
      padding-block-end: 10px;
      height: unset;
    }
    .flash-message.flash-message--shown {
      margin-block-start: 20px;
      margin-block-end: 0;
    }
  }
}

.modal-form--shown.shift-money-transfer-step {
  margin: 20px 30px 0;
  flex-direction: column;
  width: 450px;
}
