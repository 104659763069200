$transaction-history-margin: 20px;

.transaction-history-page {
  @extend .card;
  display: flex;
  flex-direction: column;
  margin: $transaction-history-margin;
  $height: calc(
    100% - ($breadcrumbs-height + ($transaction-history-margin * 2))
  );
  height: $height;
  max-height: $height;
  overflow: hidden;

  .chip-list {
    overflow-x: auto;
    flex-wrap: nowrap;
    scrollbar-width: thin;
    scrollbar-color: $color-mischka transparent;

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 20px;
    }

    &::-webkit-scrollbar {
      height: 6px;
      background-color: transparent;
      border-radius: 6px;
    }
  }

  .search-field {
    margin-bottom: 10px;
  }

  .transaction-history-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 24px;
    padding-inline-end: 10px;
    margin-block: 16px;
  }

  .transactions {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-block: 20px;
    margin-inline: 24px;

    &--no-data {
      display: none;
    }

    &__separator {
      border: none;
      height: 2px;
      background-color: $color-athens-gray-2;
      margin-block: 18px;
    }
  }

  .table-container {
    flex-grow: 1;
    overflow: auto;
    scrollbar-color: $color-mischka transparent;
    height: 200px;
  }

  table {
    thead {
      position: sticky;
      top: 0;
    }
    min-width: 1200px;
    width: 100%;

    .currency-label {
      color: $color-text-primary;
      font-weight: 500;
    }
  }

  .no-data {
    margin-block: 2vh;
  }
}

@media screen and (max-width: 1350px) {
  .transaction-history-page {
    .filter-bar__options {
      overflow-x: auto;
      overflow-y: hidden;
      flex-wrap: nowrap;
      padding-inline: 4px;
      padding-block: unset;
      scrollbar-width: thin;
      scrollbar-color: $color-mischka transparent;

      &::-webkit-scrollbar-thumb {
        background: $color-mischka;
        border-radius: 20px;
      }

      &::-webkit-scrollbar {
        height: 6px;
        background-color: transparent;
        border-radius: 6px;
      }

      .select-box__list-wrapper {
        position: fixed;
        min-width: 160px;
        width: unset;
        max-width: 240px;
      }
    }

    &--filter-opened .filter-bar__options {
      overflow-x: hidden;
    }
  }
}

@media screen and (max-height: 730px) {
  .transaction-history-page {
    max-height: auto;
    overflow: auto;

    .table-container {
      height: 110px;
    }
  }
}
