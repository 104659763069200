.receive-payment-information-step {
  min-width: 640px;

  .modal-form-control__input-element,
  .select-box,
  .attachment-input,
  &__brief-text-area {
    flex-basis: 400px;
    width: 400px;
  }

  .modal-form-control {
    .select-box__handle {
      max-width: unset;
      width: 100%;
    }
  }

  &__min-max-amount-container {
    position: relative;
    top: -8px;
    justify-content: flex-end;
  }

  &__min-max-amount {
    font-weight: 500;
    font-size: toRem(14);
    color: $color-text-secondary;
    flex-basis: 396px;
  }

  &__brief-text-area {
    min-height: 120px;
    max-height: 270px;
    padding: 8px;
    resize: vertical;

    &:disabled {
      resize: none;
      cursor: not-allowed;
    }
  }

  &__brief-label {
    align-self: flex-start;
  }

  .select-box__list-wrapper {
    z-index: 2;
  }
}

.receive-payment-overview-step {
  min-width: 700px;
  flex-direction: row;

  &__transaction-info {
    width: 400px;
    margin-block-start: 30px;

    & > :first-child {
      margin-block-end: 18px;
    }
  }

  &__info-row {
    display: flex;
    color: $color-text-primary;
    gap: 18px;

    &--secondary {
      color: $color-text-secondary;
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__info-label {
    flex-basis: 160px;
    color: inherit;
  }

  &__info-value {
    flex: 1;
    max-width: 220px;
    color: inherit;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__brief-info-value {
    font-weight: 400;
    text-align: justify;
    word-wrap: break-word;
  }

  &__readable-id-preview {
    width: 300px;
    margin-block: auto;
  }

  .static-qr-code,
  .canvas > svg {
    background-color: $color-aqua-haze;
  }
  .static-qr-code {
    border-radius: 6px;
  }
  .static-qr-code-source {
    margin-bottom: 30px;
  }
}
