.statistics-card {
  display: flex;
  align-items: center;
  padding: 20px 25px;
  gap: 14px;
  flex-basis: 300px;

  &__icon-container {
    --current-color: #{$color-primary};
    --background-color: #{$color-primary-100};
    --border-color: #{$color-magnolia};

    $icon-container-size: 62px;

    color: var(--current-color);
    border-radius: 50%;
    width: $icon-container-size;
    height: $icon-container-size;
    background-color: var(--background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 8px solid var(--border-color);

    &--secondary {
      --current-color: #{$color-green-haze};
      --background-color: #{$color-scandal};
      --border-color: #{$color-foam};
    }

    &--tertiary {
      --current-color: #{$color-fiord};
      --background-color: #{$color-athens-gray-4};
      --border-color: #{$color-athens-gray-5};
    }
  }

  &__title {
    font-size: toRem(14);
    color: $color-text-primary;
  }

  &__info {
    margin-top: 7px;
    font-size: toRem(22);
    color: $color-quaternary;
    font-weight: 600;
    min-height: 26px;
  }
}
