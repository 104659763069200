@use "sass:color";

.toggle-btn {
  background-color: $color-athens-gray-2;
  color: $color-text-secondary;
  font-weight: 500;
  border-radius: 6px;
  padding-block: 4px;
  padding-inline: 6px;
  font-size: toRem(14);
  text-align: center;
  appearance: none;
  border: none;
  @include transitioning(200ms, background-color, border-width);

  &,
  & > * {
    cursor: pointer;
  }

  &--checked {
    background-color: $color-primary-lighter;
    color: $color-primary;
  }

  &:hover,
  &:focus {
    background-color: color.change($color-primary-lighter, $lightness: 90%);
    color: $color-primary;
  }
}
