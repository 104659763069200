.otp-form-wrapper {
  max-width: 395px;
  margin-inline-start: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .user-info {
    text-align: center;

    &__avatar {
      text-overflow: hidden;
      color: transparent;

      &--alt-shown {
        color: inherit;
      }
    }

    &__title {
      margin-block-start: 10px;

      &--invisible {
        visibility: hidden;
      }
    }

    &__message {
      margin-block-start: 5px;
      padding-inline: 10%;

      &--invisible {
        visibility: hidden;
      }
    }
  }

  .user-info + .flash-message {
    margin-block: 14px;
  }

  .flash-message + .otp-form {
    margin-block-start: 0px;
  }

  .otp-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-block-start: 78px;
    margin-block-end: 20px;

    &--invisible {
      visibility: hidden;
    }

    &__entry-label {
      font-weight: 500;
      margin-block-end: 10px;
      display: block;
    }

    &__submit-btn {
      height: 38px;
      border-radius: 6px;
      padding-inline: 20px;
      padding-block: 10px;
      cursor: pointer;
      &:disabled {
        cursor: auto;
      }
    }

    .button--loading {
      padding-block: 0;
      .spinner {
        margin: auto;
      }
    }
  }

  .logout-container {
    text-align: center;
  }

  .logout-btn {
    font-size: toRem(14);
    font-weight: 600;
    padding-inline: 8px;
    padding-block: 4px;
    margin-inline: 4px;
    text-underline-offset: 4px;
  }
}

@media (max-width: 1200px) {
  .otp-form-wrapper {
    margin-inline-start: 10%;
  }
}

@media (min-height: 750px) {
  .otp-form-wrapper {
    justify-content: flex-start;
    padding-block-start: 10vh;
  }
}

@media (max-width: 872px), screen and (orientation: portrait) {
  .otp-form-wrapper {
    margin-inline: auto;
  }
}
