@use "sass:color";
$pending-approvals-page-margin: 20px;

.pending-approvals-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: $pending-approvals-page-margin;
  height: calc(
    100% - ($breadcrumbs-height + ($pending-approvals-page-margin * 2))
  );
  overflow: hidden;

  &__not-found-container {
    height: 100%;
    display: flex;
    margin-block: 2vh;
  }

  .pending-approvals-card {
    @extend .card;
    width: 100%;
    display: flex;
    min-height: 100%;
    flex-direction: column;
    overflow-y: auto;

    &--no-data {
      display: none;
    }

    &__header {
      display: flex;
      margin-block: 16px;
      align-items: center;
      padding-inline-end: 10px;
      padding-inline-start: 24px;
      justify-content: space-between;
    }
  }

  .pending-approvals {
    flex: 1;
    display: flex;
    $button-size: 24px;
    margin-block: 20px;
    margin-inline: 24px;
    flex-direction: column;

    .action-cell {
      display: flex;
    }

    &__action-button {
      padding-block: 0;
      width: $button-size;
      height: $button-size;

      &:first-child {
        margin-inline-end: 5px;
      }
      &:last-child {
        margin-inline: 5px;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &--no-data {
      display: none;
    }
  }

  .table-container {
    flex-grow: 1;
    height: 200px;
    overflow: auto;
    scrollbar-color: $color-mischka transparent;

    &__separator {
      height: 2px;
      border: none;
      margin-block: 18px;
      background-color: $color-athens-gray-2;
    }
  }

  #pending-approvals-type-options {
    width: 160px;
  }

  .search-field {
    margin-bottom: 10px;
  }

  table {
    width: 100%;

    & thead {
      top: 0;
      position: sticky;
    }
  }
}

@media screen and (max-height: 730px) {
  .pending-approvals-page {
    max-height: auto;
    overflow: auto;

    .pending-approvals-card .table-container {
      height: 110px;
    }
  }
}
