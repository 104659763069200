// General Colors:
$color-white: #ffffff;
$color-black: #000000;
$color-rhino: #26314c;
$color-rhino-light: #2d3a59;
$color-cape-cod: #384242;
$color-shark: #24292e;
$color-darker-shark: #1b1f23;
$color-fiord: #475467;
$color-parsley: #144620;
$color-falu-red: #86181d;
$color-brick-red: #cc2944;
$color-valencia: #d73a49;
$color-froly: #f97583;
$color-mandy: #e85b59;
$color-chablis: #ffeef0;
$color-cardinal: #cb2431;
$color-sunflower: #e4c630;
$color-porsche: #e8b55f;
$color-forest-green: #228b22;
$color-green-haze: #039855;
$color-eucalyptus: #28a745;
$color-emerald: #5ac076;
$color-scandal: #d1fadf;
$color-foam: #ecfdf3;
$color-shuttle-gray: #586069;
$color-regent-gray: #959da5;
$color-san-marino: #3f5eaf;
$color-gigas: #4f3faf;
$color-science-blue: #0366d6;
$color-alice-blue: #f1f8ff;
$color-selago: #e2e9fb;
$color-blue-chalk: #f4ebff;
$color-magnolia: #f9f5ff;
$color-blue-wale: #05264c;
$color-jacksons-purple: #312386;
$color-melrose: #b2c8ff;
$color-blue-zodiac: #0b1c3e;
$color-periwinkle: #ccdaff;
$color-chambray: #324b8c;
$color-san-marino-lighter: #3f5fb0;
$color-aqua-haze: #f6f8fa;
$color-athens-gray-1: #f3f3f4;
$color-athens-gray-2: #e1e4e8;
$color-athens-gray-3: #fafbfc;
$color-athens-gray-4: #f2f4f7;
$color-athens-gray-5: #f9fafb;
$color-athens-gray-6: #f3f4f6;
$color-athens-gray-7: #edeff2;
$color-white-lilac: #e6eaf5;
$color-mischka: #ced1d9;
$color-silver: #c4c4c4;
$color-scotch-mist: #fffbdd;
$color-pirate-gold: #b08800;
$color-iron: #cdd0d4;
$color-iron-lighter: #d1d5da;
$color-vanilla-strawberry: #ffe3e6;
$color-old-brick: #9e1c23;
$color-tahiti-gold: #d48806;
$color-grass-green: #389e0d;
$color-saveloy: #ad2102;
$color-london-rain: #0050b3;
$color-ebony: #101828;

// Design System Colors:
$color-primary: $color-san-marino;
$color-primary-100: $color-blue-chalk;
$color-secondary: $color-gigas;
$color-tertiary: $color-jacksons-purple;
$color-quaternary: $color-blue-zodiac;
$color-primary-lighter: $color-periwinkle;
$color-primary-darker: $color-chambray;
$color-linear-gradient-from: $color-san-marino-lighter;
$color-background-warning: $color-scotch-mist;
$color-background-error: $color-vanilla-strawberry;

// Design System Text Colors:
$color-text-primary: $color-shark;
$color-text-secondary: $color-shuttle-gray;
$color-text-disabled: $color-regent-gray;

$color-main-background: $color-aqua-haze;
$color-main-border: $color-athens-gray-2;

// Design System Gradients
$color-linear-gradient-80: linear-gradient(
  180deg,
  $color-linear-gradient-from 0%,
  #4457b0 100%
);
$color-linear-gradient-80: linear-gradient(
  180deg,
  $color-linear-gradient-from 0%,
  #4652b0 100%
);
$color-linear-gradient-80: linear-gradient(
  180deg,
  $color-linear-gradient-from 0%,
  #4b49b0 100%
);
$color-linear-gradient-80: linear-gradient(
  180deg,
  $color-linear-gradient-from 0%,
  #4f40b0 100%
);
