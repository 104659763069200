.dialog {
  &__overlay {
    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    animation: fade-in 50ms ease-in-out forwards;
  }

  &__content {
    animation: fade-in 50ms ease-in forwards;
  }

  &__content:focus {
    outline: none;
  }
}
