@use "sass:color";
@include build-translate-x(modal-form-slide-in, 0vw);

$preview-header-height: 48px;

.upload-file-flash-message {
  max-width: 560px;
  margin-block-start: 20px;
}

.bulk-transfer-process {
  .authorize-pin-container {
    max-width: 642px;

    #authorize-pin-form.pin {
      padding-block-start: 30px;
      padding-block-end: 10px;
      height: unset;
    }
    .flash-message.flash-message--shown {
      margin-block-start: 20px;
      margin-block-end: 0;
    }
  }
}

.bulk-transfer-authorization-and-confirmation-step {
  min-width: 600px;
}

.bulk-transfer-confirmation {
  flex: 1;

  &__heading {
    font-size: toRem(12);
    font-weight: 700;
    color: $color-text-secondary;
    margin-block-start: 10px;
    margin-block-end: 5px;
  }

  &__employees-container {
    padding-block: 12px;
  }

  &__employees-wrapper {
    min-height: 150px;
    max-height: 200px;
    overflow: auto;
    padding-inline-start: 4px;
    padding-inline-end: 8px;
    margin-inline-end: -8px;

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
      border-radius: 6px;
    }
  }

  &__total-amount {
    background-color: $color-main-background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-block: 12px;
    border-radius: 6px;
  }

  &__total-amount-amount {
    font-weight: 900;
    color: $color-text-primary;
    font-size: toRem(22);
  }

  &__total-amount-currency {
    color: $color-text-disabled;
    font-size: toRem(12);
  }

  &__date {
    margin-top: 16px;
    text-align: center;
    color: $color-text-secondary;
    line-height: 22px;
  }

  &__date-of-creation {
    font-size: toRem(14);
  }
}
