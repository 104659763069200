@use "sass:color";

.user-qrcode-detail {
  & .user-qrcode-detail {
    &__user-info {
      display: flex;
      text-align: center;
      align-items: center;
      margin: 20px 20px 40px;
      flex-direction: column;
    }

    &__user-info-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 80px;
      height: 80px;
    }

    &__user-info-fallback-icon {
      background-color: $color-shuttle-gray;
    }

    &__user-info-name {
      margin-top: 12px;
      font-size: toRem(22);
      font-weight: 600;
      color: $color-text-secondary;
      unicode-bidi: "plaintext";
    }

    &__user-info-business-category,
    &__user-info-phone-number {
      margin-top: 4px;
      font-size: toRem(16);
      font-weight: 400;
      color: $color-text-disabled;
    }
  }

  & .qr-code__section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      margin-top: 70px;
      text-decoration: none;
    }

    & .download-qrcode-button {
      width: 215px;
      gap: 5px;
    }
  }
}
