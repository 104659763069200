.team-salary-distribution__container {
  max-height: 100vh;

  &--min-width {
    min-width: 600px;
  }

  .authorize-pin-container {
    width: 600px;

    #authorize-pin-form.pin {
      padding-block-start: 30px;
      padding-block-end: 10px;
      height: unset;
    }
    .flash-message.flash-message--shown {
      margin-block-start: 20px;
      margin-block-end: 0;
    }
  }
}

.team-selection-step {
  width: 500px;

  .month-picker__input-wrapper {
    flex-basis: 300px;
  }

  .select-box {
    flex-basis: 300px;
  }

  .select-box__handle {
    max-width: unset;
    width: 100%;
  }

  &__search-input-wrapper {
    position: relative;

    .input {
      padding-inline-start: 26px;
    }
  }

  &__search-input-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 6px;
    margin-block: auto;
    color: $color-iron-lighter;
  }

  &__teams-and-employees-container {
    flex-direction: column;
    align-items: normal;
    gap: 10px;
  }

  &__teams-and-employees-wrapper {
    overflow-y: auto;
    max-height: 285px;
    scrollbar-width: thin;
    scrollbar-color: $color-mischka transparent;
    background-color: $color-main-background;

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
      border-radius: 6px;
    }

    &,
    .spinner-container {
      min-height: 100px;
    }
  }

  &__teams-and-employees {
    border-inline: 2px solid $color-athens-gray-2;
    min-height: 280px;
  }

  &__no-teams-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-aqua-haze;
    border-inline: 2px solid $color-athens-gray-2;
    text-align: center;
    gap: 20px;
    padding-inline: 20px;
    min-height: 280px;

    & > * {
      @include appearAfterMS(500, 400);
    }

    & + .team-selection-step__teams-and-employees {
      min-height: auto;
    }

    &-title {
      font-size: toRem(16);
      font-weight: 500;
    }

    &-text {
      font-size: toRem(14);
    }
  }

  &__top-border,
  &__bottom-border {
    position: sticky;
    width: 100%;
    height: 0px;
    border-top: 2px solid $color-athens-gray-2;
  }
  &__top-border {
    top: 0;
  }
  &__bottom-border {
    bottom: 0;
  }

  &__selected-salary-amount {
    border: 2px solid $color-athens-gray-2;
    background-color: $color-aqua-haze;
    text-align: center;
    padding-block: 12px;
    font-size: toRem(20);
  }
}

.team-salary-distribution {
  &-authorization-and-confirmation-step {
    .team-salary-distribution-confirmation {
      flex: 1;

      &__heading {
        font-size: toRem(14);
        font-weight: 700;
        color: $color-text-secondary;
        margin-block-start: 10px;
        margin-block-end: 5px;
      }

      &__teams-and-employees-container {
        padding-block: 12px;
      }

      &__teams-and-employees-wrapper {
        min-height: 150px;
        max-height: 200px;
        overflow: auto;
        display: flex;
        row-gap: 15px;
        flex-direction: column;
        padding-inline-start: 4px;
        padding-inline-end: 8px;
        margin-inline-end: -8px;
        scrollbar-width: thin;
        scrollbar-color: $color-mischka transparent;

        &::-webkit-scrollbar-thumb {
          background: $color-mischka;
          border-radius: 6px;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: transparent;
          border-radius: 6px;
        }
      }

      &__total-salary {
        background-color: $color-aqua-haze;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding-block: 12px;
        border-radius: 6px;
      }

      &__total-salary-amount {
        font-weight: 900;
        color: $color-text-primary;
        font-size: toRem(24);
      }

      &__total-salary-currency {
        color: $color-text-disabled;
        font-size: toRem(14);
      }

      &__date {
        margin-top: 16px;
        text-align: center;
        color: $color-text-secondary;
        line-height: 22px;
      }

      &__date-of-creation {
        font-size: toRem(16);
      }

      &__date-of-salary {
        font-size: toRem(16);
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-height: 750px) {
  .team-salary-distribution__container {
    max-height: 90vh;
  }
}
