@use "sass:color";

.phone-number {
  display: flex;
  align-items: stretch;
  border-radius: 6px;
  position: relative;

  &--small {
    height: 28px;
  }
  &--medium {
    height: 32px;
  }
  &--large {
    height: 40px;
  }

  &__prefix-code {
    display: flex;
    align-items: center;
    padding: 10px 7px;
    background-color: $color-athens-gray-7;
    border: 1px solid color.change($color-darker-shark, $alpha: 0.15);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    font-size: toRem(14);
  }

  &__input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    direction: inherit;
    flex: 1;
  }

  &:focus-within {
    box-shadow: 0px 0px 0px 3px color.change($color-science-blue, $alpha: 0.302);
  }

  &:focus-within > &__prefix-code {
    border-color: $color-primary;
  }

  &:focus-within > &__input:focus {
    box-shadow: none;
    border-inline-start-width: 0;
  }
}

[dir="rtl"] {
  .phone-number__prefix-code {
    border-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .phone-number__input {
    border-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
