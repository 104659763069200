@import-normalize;

@import "./tools/to-rem.scss";

@import "./colors";

@import "./globals";

@import "./_animations.scss";

@import "./utils/terminal-password-stengths.scss";
@import "./utils/_scrollbar";

// Components
@import "./components/topic-tag.scss";
@import "./components/tooltip.scss";
@import "./components/spinner.scss";
@import "./components/button.scss";
@import "./components/toggle-button.scss";
@import "./components/inputs.scss";
@import "./components/attachment-input.scss";
@import "./components/popover.scss";
@import "./components/select-box.scss";
@import "./components/links.scss";
@import "./components/tooltips.scss";
@import "./components/cards.scss";
@import "./components/pagination.scss";
@import "./components/chip-list.scss";
@import "./components/filter-bar.scss";
@import "./components/no-data.scss";
@import "./components/toasts.scss";
@import "./components/date-range-picker.scss";
@import "./components/qrcode.scss";
@import "./components/topbar.scss";
@import "./components/breadcrumbs.scss";
@import "./components/statistics-card.scss";
@import "./components/analytics/dashboard/balance-box.scss";
@import "./components/table.scss";
@import "./components/analytics/balance/stacked-balance-history-chart.scss";
@import "./components/navbar-link.scss";
@import "./components/search-field.scss";
@import "./components/square-card.scss";
@import "./components/dropdown.scss";
@import "./components/modal-progressbar.scss";
@import "./components/phonenumber-input.scss";
@import "./components/password-input.scss";
@import "./components/password-strength.scss";
@import "./components/modal-forms.scss";
@import "./components/process-modal.scss";
@import "./components//confirm-box.scss";
@import "./components/terminal-creation-process.scss";
@import "./components/editing-terminal-name-process.scss";
@import "./components/terminal-deletion-process.scss";
@import "./processes/employee-deletion-process.scss";
@import "./components/details-sidebar.scss";
@import "./components/navbar.scss";
@import "./components/date-picker.scss";
@import "./components/month-picker.scss";
@import "./components/flash-message.scss";
@import "./components/accordion/accordion.scss";
@import "./components/accordion/selectable-accordion.scss";
@import "./components/summary-table.scss";
@import "./components/progress-bar.scss";
@import "./components/static-qrcode.scss";
@import "./components/pin-input.scss";
@import "./components/sidebars/role-details.scss";
@import "./components/sidebars/payroll-details.scss";
@import "./components/sidebars/account-settings.scss";
@import "./components/sidebars/employee-details.scss";
@import "./components/sidebars/user-qrcode-details.scss";
@import "./components/sidebars/user-details.scss";
@import "./components/switch.scss";
@import "./components/flex-spacer.scss";
@import "./components/dialog.scss";
@import "./components/document-view.scss";

// Pages/Containers
@import "./components/auth-presentation.scss";
@import "./components/error-boundaries/root-error-boundary.scss";
@import "./pages/login-qrcode.scss";
@import "./pages/verify-otp.scss";
@import "./pages/analytics/dashboard.scss";
@import "./pages/analytics/balance.scss";
@import "./pages/analytics/transaction-history.scss";
@import "./pages/terminals.scss";
@import "./pages/employees.scss";
@import "./pages/terminal-details.scss";
@import "./pages/teams.scss";
@import "./pages/payroll.scss";
@import "./pages/team-details.scss";
@import "./pages/transfer.scss";
@import "./pages/roles.scss";
@import "./pages/users.scss";
@import "./pages/pending-approvals.scss";
@import "./pages/404.scss";
@import "./pages/not-allowed-user.scss";

// Processes components
@import "./components/processes/authorization-and-confirmation.scss";
@import "./components/processes/pin-authorization-step.scss";

// Processes
@import "./components/terminal-creation-process.scss";
@import "./components/editing-terminal-name-process.scss";
@import "./components/terminal-deletion-process.scss";
@import "./components/shift-money-process.scss";
@import "./components/changing-terminal-password.scss";
@import "./components/employee-process.scss";
@import "./processes/add-employee-process.scss";
@import "./processes/edit-employee-process.scss";
@import "./processes/team-deletion-process.scss";
@import "./processes/team-processes.scss";
@import "./processes/team-salary-distribution.scss";
@import "./processes/file-salary-distribution.scss";
@import "./processes/team-member-deletion-process.scss";
@import "./processes/receive-payment-process.scss";
@import "./processes/bank-transfer.scss";
@import "./processes/bulk-transfer.scss";
@import "./processes/create-user-process.scss";
@import "./processes/delete-user-process.scss";
@import "./processes/delete-role-process.scss";
@import "./processes/approve-pending-process.scss";
@import "./processes/reject-pending-process.scss";
@import "./processes/role-process.scss";
@import "./processes/update-password-process.scss";
@import "./processes/set-and-verify-pin-process.scss";
@import "./processes/change-user-activation-status-process.scss";
@import "./processes/toggle-terminal-visibility-for-agents.scss";
