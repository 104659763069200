$padding-inline-start: 24px;

table.standard-table {
  border-collapse: collapse;
  font-size: toRem(14);

  thead {
    background-color: $color-aqua-haze;
    color: $color-text-primary;
    tr {
      height: 40px;
    }
    th {
      border-width: 0;
      font-weight: 500;
      color: $color-shark;
      text-transform: uppercase;
    }
  }

  tbody {
    color: $color-text-secondary;
    tr {
      height: 35px;
      width: 100%;
      border-top: 1px solid $color-aqua-haze;

      &:first-child {
        border-top: 1px solid $color-white;
      }

      &.clickable {
        cursor: pointer;

        &:hover {
          background-color: $color-aqua-haze;
        }
      }
    }
  }

  th,
  td {
    padding-inline-start: $padding-inline-start;
  }
}

.table-header {
  &--start-aligned {
    text-align: left;
  }

  &--end-aligned {
    text-align: right;
  }

  &--center-aligned {
    text-align: center;
  }

  .sorting-indicator {
    display: none;
  }

  &--sortable {
    cursor: pointer;
    position: relative;

    .sorting-indicator {
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      left: 8px;
      top: 0;

      &__up {
        transform: rotate(180deg);
        margin-bottom: -5px;
      }
      &__down {
        margin-top: -5px;
      }

      &__up,
      &__down {
        color: $color-silver;

        &--active {
          color: $color-shark;
        }
      }
    }
  }
}

.table-cell {
  &--start-aligned {
    text-align: left;
  }
  &--end-aligned {
    text-align: right;
  }
  &--center-aligned {
    text-align: center;
  }
}

[dir="rtl"] {
  table {
    .table-header {
      &--start-aligned {
        text-align: right;
      }

      &--end-aligned {
        text-align: left;
      }

      &--sortable .sorting-indicator {
        right: 8px;
        left: initial;
      }
    }
  }

  .table-cell {
    &--start-aligned {
      text-align: right;
    }
    &--end-aligned {
      text-align: left;
    }
  }
}
