@use "sass:color";

$users-page-margin: 20px;

.users-page {
  margin: $users-page-margin;
  $height: calc(100% - ($breadcrumbs-height + ($users-page-margin * 2)));
  height: $height;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .no-users-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .action-cell {
    gap: 20px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .users-card {
    @extend .card;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;

    &--no-data {
      display: none;
    }

    &__header {
      padding-inline-start: 24px;
      margin-block: 16px;
    }

    .users {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-block: 20px;
      margin-inline: 24px;

      &--no-data {
        display: none;
      }

      &__delete-button {
        width: 24px;
        height: 24px;
      }

      .table-container {
        flex-grow: 1;
        overflow: auto;
        scrollbar-color: $color-mischka transparent;
        height: 200px;

        &__separator {
          border: none;
          height: 2px;
          background-color: $color-athens-gray-2;
          margin-block: 18px;
        }

        &--last-column-fixed {
          position: relative;

          th:last-child,
          td:last-child {
            box-shadow: 1px 0px 0px 0px $color-white;

            &::before {
              content: " ";
              height: 100%;
              position: absolute;
              top: 0;
              width: 15px;
              box-shadow: -3px 0 3px -3px color.change(
                  $color-black,
                  $alpha: 0.6
                ) inset;
              left: -15px;
            }
          }
        }

        &--first-column-fixed {
          position: relative;

          th:first-child,
          td:first-child {
            &::after {
              content: " ";
              height: 100%;
              position: absolute;
              top: 0;
              width: 15px;
              box-shadow: 3px 0 3px -3px color.change($color-black, $alpha: 0.6)
                inset;
              right: -15px;
            }
          }
        }

        tr:hover {
          td {
            background-color: $color-aqua-haze;
          }
        }
      }

      table {
        border-spacing: 0;
        border-collapse: separate;

        thead tr th {
          position: sticky;
          top: 0;
          background-color: $color-aqua-haze;

          &:last-child {
            position: sticky;
            right: 0;
            background-color: $color-aqua-haze;
            z-index: 1;
          }

          &:first-child {
            position: sticky;
            left: 0;
            background-color: $color-aqua-haze;
            z-index: 1;
          }
        }

        tbody tr {
          td:last-child {
            position: sticky;
            right: 0;
            background-color: $color-white;
          }
          td:first-child {
            position: sticky;
            left: 0;
            background-color: $color-white;
          }
        }

        width: 100%;
        min-width: 700px;
      }
    }
  }

  .no-user-found-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 2vh;
  }
}

@media (max-width: 980px) {
  .users #users-table-container {
    thead tr th {
      &:first-child {
        left: auto;
      }
    }

    tbody tr {
      td:first-child {
        position: static;
      }
    }

    th:first-child,
    td:first-child {
      &::after {
        content: none;
      }
    }
  }
}

@media screen and (max-height: 730px) {
  .users-page {
    max-height: auto;
    overflow: auto;

    .users-card .table-container {
      height: 110px;
    }
  }
}
