$teams-page-margin: 20px;

.teams-page {
  margin: $teams-page-margin;
  $height: calc(100% - ($breadcrumbs-height + ($teams-page-margin * 2)));
  min-height: $height;

  .teams {
    display: flex;
    flex-wrap: wrap;
    gap: 42px;
  }

  &--no-data {
    display: flex;
    align-items: center;
  }

  .no-teams-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown-list {
    width: 115px;
  }

  .square-card__subtitle {
    font-size: toRem(14);
    color: $color-text-disabled;
  }
}
