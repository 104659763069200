$primary-transition-time: 0.35s;
$secondary-transition-time: 0.2s;

@mixin transitioning($time, $properties...) {
  transition: $properties $time ease-in-out;
}

@mixin common-transition {
  @include transitioning($primary-transition-time, all);
}

@mixin link-transition {
  @include transitioning($secondary-transition-time, all);
}

@mixin breathe($animation-name, $breathe-property, $breathe-value) {
  @keyframes #{$animation-name} {
    50% {
      #{$breathe-property}: $breathe-value;
    }
  }
}

@mixin breathe-scale($animation-name, $scale-factor) {
  @include breathe($animation-name, transform, scale($scale-factor));
}

@mixin spin($animation-name, $from, $to) {
  @keyframes #{$animation-name} {
    from {
      transform: rotate($from);
    }
    to {
      transform: rotate($to);
    }
  }
}

@mixin full-spin($time) {
  animation: full-spin-animation $time linear infinite;
}

@include spin(full-spin-animation, 0deg, 360deg);
@include breathe-scale(breathe-scale-1_1, 1.1);
@include breathe(breathe-opacity-0_8, opacity, 0.8);

@mixin build-slide-animation($name, $transform) {
  @keyframes #{$name} {
    to {
      transform: #{$transform};
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@mixin buildVerticalMovementAnimation($name, $distance) {
  @keyframes vertical-movement-#{$name} {
    from {
      transform: translateY($distance);
    }
    to {
      transform: translateY(0);
    }
  }
}

@mixin appearAfterMS($time, $delay) {
  animation: fade-in #{$time}ms ease-out forwards;
  animation-delay: #{$delay}ms;
  opacity: 0;
}

@mixin build-translate-x($name, $to) {
  @keyframes #{$name} {
    to {
      transform: translateX($to);
    }
  }
}
