@use "sass:color";

.attachment-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 2px;
  box-shadow: inset 0px 1px 2px 0px
    color.change($color-darker-shark, $alpha: 0.075);
  border: 1px solid $color-athens-gray-2;
  border-radius: 6px;
  font-size: toRem(14);
  min-height: 32px;

  &--disabled {
    background-color: $color-aqua-haze;
  }

  &__placeholder {
    color: $color-text-disabled;
    margin-inline-start: 6px;
    cursor: default;
    user-select: none;
  }

  &__selected-file-names {
    display: flex;
    flex: 1;
    align-items: center;
    column-gap: 10px;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: $color-mischka transparent;

    .topic-tag {
      white-space: nowrap;
      direction: ltr;
    }

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $color-iron;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $color-text-secondary;
    }
  }

  &__trigger-btn {
    padding: 6px 14px;
    border-radius: 6px;
  }
}
