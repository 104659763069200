@use "sass:color";

.confirm-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: color.change($color-black, $alpha: 0.2);

  &__container {
    width: 440px;
    padding: 20px;
    background-color: $color-white;
    border: 1px solid $color-athens-gray-2;
    box-shadow: 0px 2px 8px color.change($color-black, $alpha: 0.05);
    border-radius: 6px;
    text-align: center;
  }
  &__title {
    font-weight: 500;
    margin-top: 25px;
    margin-bottom: 20px;
    font-size: toRem(20);
    line-height: 24px;
    color: $color-text-primary;
  }

  &__body {
    line-height: 25px;
    margin-bottom: 30px;
    letter-spacing: 0.05em;
    color: $color-text-secondary;
    overflow-wrap: anywhere;
  }
  &__buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
  &__button {
    flex-grow: 1;
    padding: 10px;
    min-width: 185px;
    border-radius: 6px;
    text-transform: uppercase;
  }
}

[dir="rtl"] {
  .confirm-box__body {
    letter-spacing: unset;
  }
}
