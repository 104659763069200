@use "sass:color";

.chart-tooltip {
  box-shadow: inset 0px 0px 1px color.change($color-black, $alpha: 0.15);
  background-color: color.change($color-white, $alpha: 0.85);
  border-radius: 5px;
  padding-inline: 12px;
  padding-block: 8px;

  & > *:not(:last-child) {
    margin-block-end: 12px;
  }
}
