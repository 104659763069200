.user-detail {
  .summary-table {
    margin-top: 30px;
  }

  .summary-table tbody {
    overflow-y: scroll;
  }

  .summary-table tbody tr td:first-child {
    padding-inline-start: 10px;
    color: $color-text-primary;
  }

  .feature-summary-table tbody tr td:first-child,
  .feature-summary-table thead tr th:first-child {
    flex-basis: 45%;
  }

  &__table-amount {
    font-weight: 700;
    color: $color-text-primary;
  }

  .summary-table tbody tr td:last-child span {
    color: $color-text-secondary;
  }

  &__role-spinner {
    height: 200px;
  }

  &__phone-number .details-sidebar-body__section-row-value {
    @extend .plain-phone-number;
  }
}
