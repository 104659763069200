@use "sass:color";

.role-permission-step-process {
  width: 600px;

  .modal-form-control {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    &__role-name-label {
      margin-inline-end: 14px;
      font-weight: 500;
      font-size: toRem(14);
    }

    &__all-selection {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-label {
        font-weight: 500;
        font-size: toRem(14);
        margin-inline-start: 10px;
      }
    }

    &__limitation-label {
      flex-basis: 160px;
      font-size: toRem(14);
      font-weight: 500;
    }

    &__limitation-input-element {
      flex-basis: 300px;
    }

    &__no-limit {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &-wrapper {
      min-height: 250px;

      > :not(:last-child) {
        margin-bottom: 20px;
      }

      &--hidden {
        display: none;
      }
    }
  }

  .modal-form {
    &__limitation-header {
      background-color: $color-main-background;
      color: $color-text-secondary;
      font-weight: 500;
      font-size: toRem(14);
      padding: 10px;
      align-items: center;
      margin-top: 10px;
      width: 100%;
    }

    &-no-limitation {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-inline: 50px;
      text-align: justify;

      &__title {
        font-weight: 500;
        font-size: toRem(16);
      }

      &__text {
        font-size: toRem(14);
      }
    }
  }

  .table-container {
    max-height: 56vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: $color-mischka transparent;

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 6px;
    }
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
      border-radius: 6px;
    }

    .standard-table {
      width: 100%;

      thead {
        position: sticky;
        top: 0;

        tr th {
          color: $color-text-secondary;

          &:not(:first-child) {
            padding: 0;
            width: 70px;
          }
        }
      }

      tbody {
        color: $color-text-primary;

        tr td:not(:first-child) {
          padding: 0;
          width: 70px;
        }

        &:before {
          content: " ";
          height: 8px;
          color: transparent;
          display: block;
        }
      }
    }
  }
}

.role-confirmation {
  flex: 1;
  font-size: toRem(14);

  &__heading {
    font-weight: 700;
    color: $color-text-secondary;
    margin-block-start: 10px;
    margin-block-end: 5px;
    font-size: toRem(14);
    text-transform: uppercase;
  }

  &__permission-heading {
    color: $color-text-primary;
    font-weight: 500;
    background-color: $color-main-background;
    text-align: center;
    padding: 5px 5px 5px 10px;
    border-radius: 6px 6px 0 0;
    margin-block: 10px;
  }

  &__permission-body {
    max-height: 114px;
    overflow: auto;
    font-weight: 400;
    scrollbar-width: thin;
    scrollbar-color: $color-iron;
    padding-inline: 10px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $color-iron;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $color-text-secondary;
    }
  }

  &__permission-row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-block-end: 3px;
  }

  &__permission-key {
    flex: 40%;
  }

  &__permission-value {
    flex: 60%;
  }

  &__limitation {
    &-wrapper {
      background-color: $color-main-background;
      padding: 9px 20px;
      border-radius: 6px;
    }

    &-heading {
      font-weight: 500;
      color: $color-text-primary;
      margin-block-end: 5px;
    }

    &-body {
      font-weight: 400;
      color: $color-text-secondary;
      margin-block-start: 5px;
    }

    &-key {
      color: $color-text-primary;
    }

    &-value {
      font-weight: 700;
      color: $color-text-primary;
    }
  }

  &__limitation-row {
    display: flex;
    justify-content: space-between;
  }

  &__date {
    color: $color-text-secondary;
    font-size: toRem(16);
    font-weight: 400;
    text-align: center;
    margin-block-start: 10px;
  }
}

.add-role-process__container,
.edit-role-process__container {
  .authorize-pin-container {
    width: 600px;

    #authorize-pin-form.pin {
      padding-block-start: 30px;
      padding-block-end: 10px;
      height: unset;
    }
    .flash-message.flash-message--shown {
      margin-block-start: 20px;
      margin-block-end: 0;
    }
  }
}

.role-addition-authorization-and-confirmation-step,
.editing-role-authorization-and-confirmation-step {
  min-width: 550px;
}

@media screen and (max-height: 750px) {
  .add-role-process__container,
  .edit-role-process__container {
    max-height: 90vh;
  }
}
