@use "sass:color";
$transfer-page-margin: 20px;

.transfer-breadcrumbs {
  .dropdown-list {
    width: 100%;
  }
}

.transfer-page {
  margin: $transfer-page-margin;
  $height: calc(100% - ($breadcrumbs-height + ($transfer-page-margin * 2)));
  height: $height;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  .no-transfer-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .transfer-card {
    @extend .card;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &--no-data {
      display: none;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline-start: 24px;
      padding-inline-end: 10px;
      margin-block: 16px;
    }

    .search-field {
      margin-bottom: 10px;
    }

    .filter-bar__options {
      column-gap: 10px;

      .select-box .select-box-item {
        gap: 5px;
      }
    }

    .transfer {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-block: 20px;
      margin-inline: 24px;

      &--no-data {
        display: none;
      }

      .table-container {
        flex-grow: 1;
        overflow: auto;
        scrollbar-color: $color-mischka transparent;
        height: 200px;

        &__separator {
          border: none;
          height: 2px;
          background-color: $color-athens-gray-2;
          margin-block: 18px;
        }
      }

      table {
        thead {
          position: sticky;
          top: 0;
        }
        min-width: 1200px;
        width: 100%;

        th,
        td {
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .currency-label {
          color: $color-text-primary;
          font-weight: 500;
        }
      }

      &__table-cell-status {
        padding-block: 4px;
        padding-inline: 8px;
        border-width: 1px;
        border-style: solid;
        border-radius: 8px;
        text-transform: capitalize;
        font-size: toRem(12);
        cursor: default;
        font-weight: 500;

        &--pending {
          color: $color-tahiti-gold;
          border-color: $color-tahiti-gold;
          background-color: color.change($color-tahiti-gold, $alpha: 0.05);
        }

        &--blocked-funds {
          color: $color-cardinal;
          border-color: $color-cardinal;
          background-color: color.change($color-cardinal, $alpha: 0.05);
        }

        &--completed {
          color: $color-grass-green;
          border-color: $color-grass-green;
          background-color: color.change($color-grass-green, $alpha: 0.05);
        }
      }
    }

    .no-transfer-found-container {
      flex-grow: 1;
      display: flex;
      margin-block: 2vh;
    }
  }
}

@media screen and (max-width: 1440px) {
  .transfer-page {
    .filter-bar {
      &__options {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        scrollbar-width: thin;
        scrollbar-color: $color-mischka transparent;
        padding-inline: 4px;
        padding-block: unset;

        &::-webkit-scrollbar {
          height: 6px;
          background-color: transparent;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: $color-mischka;
          border-radius: 20px;
        }

        .select-box__list-wrapper {
          position: fixed;
          min-width: 160px;
          width: unset;
          max-width: 240px;
        }
      }
    }

    &--filter-opened .filter-bar__options {
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
}

@media screen and (max-height: 730px) {
  .transfer-page {
    max-height: auto;
    overflow: auto;

    .transfer-card .table-container {
      height: 110px;
    }
  }
}
