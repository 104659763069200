$color-link-disabled: $color-regent-gray;
$color-link-active: $color-shuttle-gray;
$breadcrumbs-height: 85px;

.breadcrumbs-navbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-color: $color-athens-gray-1;
  min-height: $breadcrumbs-height;
  padding-inline: 20px;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    color: $color-link-active;
    font-size: toRem(24);
    font-weight: 700;
    margin-bottom: 5px;
    letter-spacing: 0.06rem;
    overflow-wrap: anywhere;
  }

  &__breadcrumbs {
    &-wrapper {
      display: flex;
      list-style-type: none;
      font-size: toRem(14);
    }
    &-item {
      color: $color-link-disabled;
      display: flex;
      align-items: center;

      &--active {
        color: $color-link-active;
      }
    }
    &-arrow {
      transform: rotate(-0.25turn);
      margin-inline-start: 4px;
      margin-inline-end: 2px;

      &--rotated {
        transform: rotate(-0.75turn);
      }
    }
  }
}

.action-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-inline-end: 20px;
}

.action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: toRem(14);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  text-transform: uppercase;

  &__text {
    margin-inline-start: 7px;
  }
}

[dir="rtl"] .breadcrumbs-navbar__title {
  letter-spacing: 0;
}
