.authorize-pin-container {
  .pin__input--content-hidden {
    font-size: toRem(70);
  }

  @-moz-document url-prefix() {
    .pin__input--content-hidden {
      font-size: 480%;
    }
  }
  .pin {
    padding-block-start: 18px;
    padding-block-end: 16px;
    min-height: 110px;

    &__input {
      padding-top: 8px;
      width: 60px;
      height: 80px;
    }
  }

  .flash-message--shown {
    margin-block-end: 10px;
  }
}

.pin-container--hidden {
  display: none;
}
