$payroll-page-margin: 20px;

.payroll-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $payroll-page-margin;
  $height: calc(100% - ($breadcrumbs-height + ($payroll-page-margin * 2)));
  height: $height;
  position: relative;
  overflow: hidden;

  .filter-bar__options {
    column-gap: 10px;
  }

  .chip-list {
    overflow-x: auto;
    flex-wrap: nowrap;
    scrollbar-width: thin;
    scrollbar-color: $color-mischka transparent;

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 20px;
    }

    &::-webkit-scrollbar {
      height: 6px;
      background-color: transparent;
      border-radius: 6px;
    }
  }

  .search-field {
    margin-bottom: 10px;
  }

  .payrolls-card {
    @extend .card;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    &--no-data {
      display: none;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline-start: 24px;
      padding-inline-end: 10px;
      margin-block: 16px;
    }

    .payrolls {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-block: 20px;
      margin-inline: 24px;

      &--no-data {
        display: none;
      }
    }

    .table-container {
      flex-grow: 1;
      overflow: auto;
      scrollbar-color: $color-mischka transparent;
      height: 200px;

      &__separator {
        border: none;
        height: 2px;
        background-color: $color-athens-gray-2;
        margin-block: 18px;
      }
    }

    table {
      thead {
        position: sticky;
        top: 0;
      }
      min-width: 1200px;
      width: 100%;

      .payroll-payees-cell,
      .payroll-payee-names {
        width: max(400px, 26vw);
      }

      .payroll-payee-names {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .no-payroll-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no-payroll-found-container {
    flex: 1;
    display: flex;
    margin-block: 2vh;
  }
}

.payroll-breadcrumbs {
  .dropdown-list {
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .payroll-page {
    .filter-bar {
      &__options {
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
        scrollbar-width: thin;
        scrollbar-color: $color-mischka transparent;
        padding-inline: 4px;
        padding-block: unset;

        &::-webkit-scrollbar {
          height: 6px;
          background-color: transparent;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: $color-mischka;
          border-radius: 20px;
        }

        .select-box__list-wrapper {
          position: fixed;
          min-width: 160px;
          width: unset;
          max-width: 240px;
        }
      }
    }

    &--filter-opened .filter-bar__options {
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
}

@media screen and (max-height: 730px) {
  .payroll-page {
    max-height: auto;
    overflow: auto;

    .payrolls-card .table-container {
      height: 110px;
    }
  }
}
