@use "sass:color";

.chip-list {
  margin-inline: 24px;
  margin-block: 5px;
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  @include common-transition();

  &-item {
    display: flex;
    gap: 4px;
    align-items: center;
    padding-block: 4px;
    padding-inline: 8px;
    background: #f6f8fa;
    border-radius: 100px;
    flex-shrink: 0;
    @include transitioning(1s, all);

    &__label {
      font-size: toRem(13);
      color: $color-primary;
    }
    &__text {
      color: $color-primary;
      font-size: toRem(13);
      font-weight: 600;
    }

    &__dismiss {
      @extend .button;
      @extend .button--iconed;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      color: $color-primary;
      border-radius: 50%;
      margin-inline-start: 6px;
    }
  }
}
