.authorization-and-confirmation-step {
  flex-direction: row;
  width: 650px;

  &--shown {
    display: flex;
  }

  &__qrcode-section-container {
    position: relative;
  }

  .qr-code-container {
    .qr-code {
      width: 270px;
      height: 300px;
      min-height: unset;

      & .regenerate-btn {
        width: 150px;
        height: 150px;

        & > span {
          font-size: toRem(14);
        }
      }

      & > .canvas {
        width: 200px;
        height: 200px;
      }

      .qr-code-source {
        font-size: toRem(20);
      }
    }
  }

  &__not-generated {
    position: absolute;
    top: 0px;
    bottom: 44px;
    right: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 980px) {
  .authorization-and-confirmation-step {
    &__not-generated {
      top: 44px;
      bottom: 0px;
    }
  }
}
