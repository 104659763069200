@use "sass:color";

$roles-page-margin: 20px;

.roles-page {
  margin: $roles-page-margin;
  $height: calc(100% - ($breadcrumbs-height + ($roles-page-margin * 2)));
  height: $height;

  .no-roles-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .roles-card {
    @extend .card;
    width: 100%;
    display: flex;
    min-height: 100%;
    flex-direction: column;

    &--no-data {
      display: none;
    }

    &__header {
      display: flex;
      margin-block: 16px;
      align-items: center;
      padding-inline-end: 10px;
      padding-inline-start: 24px;
      justify-content: space-between;
    }
  }

  .roles {
    flex: 1;
    display: flex;
    $button-size: 24px;
    margin-block: 20px;
    margin-inline: 24px;
    flex-direction: column;

    &--no-data {
      display: none;
    }

    &__edit-button {
      width: $button-size;
      height: $button-size;
      margin-inline-end: 5px;
    }

    &__delete-button {
      width: $button-size;
      height: $button-size;
    }

    .action-cell {
      display: flex;
      justify-content: center;
    }
  }

  .table-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .table-header:last-child {
    width: 100px;
  }

  .table-cell:nth-child(2) {
    max-width: 750px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .table-container {
    flex-grow: 1;
    overflow: auto;
    scrollbar-color: $color-mischka transparent;
    height: 200px;

    &__separator {
      border: none;
      height: 2px;
      background-color: $color-athens-gray-2;
      margin-block: 18px;
    }

    tr:hover {
      td {
        background-color: $color-aqua-haze;
      }
    }

    &--last-column-fixed {
      position: relative;

      th:last-child,
      td:last-child {
        box-shadow: 1px 0px 0px 0px $color-white;

        &::before {
          content: " ";
          height: 100%;
          position: absolute;
          top: 0;
          width: 15px;
          box-shadow: -3px 0 3px -3px color.change($color-black, $alpha: 0.6) inset;
          left: -15px;
        }
      }
    }

    &--first-column-fixed {
      position: relative;

      th:first-child,
      td:first-child {
        &::after {
          content: " ";
          height: 100%;
          position: absolute;
          top: 0;
          width: 15px;
          box-shadow: 3px 0 3px -3px color.change($color-black, $alpha: 0.6) inset;
          right: -15px;
        }
      }
    }
  }

  table {
    border-spacing: 0;
    border-collapse: separate;

    thead tr th {
      position: sticky;
      top: 0;
      background-color: $color-aqua-haze;

      &:last-child {
        position: sticky;
        right: 0;
        background-color: $color-aqua-haze;
        z-index: 1;
      }

      &:first-child {
        position: sticky;
        left: 0;
        background-color: $color-aqua-haze;
        z-index: 1;
      }
    }

    tbody tr {
      td:last-child {
        position: sticky;
        right: 0;
        background-color: $color-white;
      }
      td:first-child {
        position: sticky;
        left: 0;
        background-color: $color-white;
      }
    }

    min-width: 1200px;
    width: 100%;
  }
}

@media (max-width: 980px) {
  .roles #roles-table-container {
    thead tr th {
      &:first-child {
        left: auto;
      }
    }

    tbody tr {
      td:first-child {
        position: static;
      }
    }

    th:first-child,
    td:first-child {
      &::after {
        content: none;
      }
    }
  }
}
