@use "sass:color";

.flash-message {
  $root: &;
  display: flex;
  column-gap: 10px;
  align-items: center;
  font-size: toRem(14);
  border-radius: 6px;
  line-height: 20px;
  padding-inline: 12px;
  overflow-y: hidden;
  transition-property: height, padding;
  transition: 200ms ease-out;

  &__icon {
    height: 16px;
  }

  &__text {
    flex: 1;
    color: $color-text-primary;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__close-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 24px;
  }

  &--shown {
    border: none;
    padding-block: 12px;
  }

  &--warning {
    border: 1px solid color.change($color-pirate-gold, $alpha: 0.2);
    background-color: $color-background-warning;

    #{$root}__icon,
    #{$root}__close-btn {
      color: $color-pirate-gold;
    }
  }

  &--error {
    border: 1px solid color.change($color-old-brick, $alpha: 0.2);
    background-color: $color-background-error;

    #{$root}__icon,
    #{$root}__close-btn {
      color: $color-old-brick;
    }
  }
}
