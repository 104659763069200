.root-error-boundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  &__title {
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: toRem(28);
    font-weight: 500;
    color: $color-text-secondary;
    @include appearAfterMS(500, 500);
  }

  &__description {
    font-size: toRem(18);
    color: $color-text-disabled;
    max-width: 500px;
    @include appearAfterMS(500, 650);
  }

  &__illustration {
    & .path-1 {
      stroke-dashoffset: 666.563720703125px;
      stroke-dasharray: 666.563720703125px;
      -webkit-transition: stroke-dashoffset 0.8s ease-in-out 0s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.8s;
      transition: stroke-dashoffset 0.8s ease-in-out 0s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.8s;
    }

    &--active .path-1 {
      stroke-dashoffset: 0;
    }

    & .path-2 {
      stroke-dashoffset: 629.7427368164062px;
      stroke-dasharray: 629.7427368164062px;
      fill: transparent;
      -webkit-transition: stroke-dashoffset 0.8s ease-in-out 0.12s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.9s;
      transition: stroke-dashoffset 0.8s ease-in-out 0.12s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.9s;
    }

    &--active .path-2 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
    }

    & .path-3 {
      stroke-dashoffset: 1254.9072265625px;
      stroke-dasharray: 1254.9072265625px;
      fill: transparent;
      -webkit-transition: stroke-dashoffset 0.8s ease-in-out 0.24s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1s;
      transition: stroke-dashoffset 0.8s ease-in-out 0.24s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1s;
    }

    &--active .path-3 {
      stroke-dashoffset: 0;
      fill: rgb(63, 94, 175);
    }

    & .path-4 {
      stroke-dashoffset: 200.6503448486328px;
      stroke-dasharray: 200.6503448486328px;
      fill: transparent;
      -webkit-transition: stroke-dashoffset 0.8s ease-in-out 0.36s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.1s;
      transition: stroke-dashoffset 0.8s ease-in-out 0.36s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.1s;
    }

    &--active .path-4 {
      stroke-dashoffset: 0;
      fill: rgb(63, 94, 175);
    }

    & .path-5 {
      stroke-dashoffset: 125.43247985839844px;
      stroke-dasharray: 125.43247985839844px;
      fill: transparent;
      -webkit-transition: stroke-dashoffset 0.8s ease-in-out 0.48s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.2000000000000002s;
      transition: stroke-dashoffset 0.8s ease-in-out 0.48s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.2000000000000002s;
    }

    &--active .path-5 {
      stroke-dashoffset: 0;
      fill: rgb(149, 157, 165);
    }

    & .path-6 {
      stroke-dashoffset: 71.09696197509766px;
      stroke-dasharray: 71.09696197509766px;
      -webkit-transition: stroke-dashoffset 0.8s ease-in-out 0.6s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.3s;
      transition: stroke-dashoffset 0.8s ease-in-out 0.6s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.3s;
    }

    &--active .path-6 {
      stroke-dashoffset: 0;
    }

    & .path-7 {
      stroke-dashoffset: 93.7674560546875px;
      stroke-dasharray: 93.7674560546875px;
      -webkit-transition: stroke-dashoffset 0.8s ease-in-out 0.72s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.4000000000000001s;
      transition: stroke-dashoffset 0.8s ease-in-out 0.72s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.4000000000000001s;
    }

    &--active .path-7 {
      stroke-dashoffset: 0;
    }
  }

  &__action-link {
    margin-top: 60px;
    padding-block: 10px;
    padding-inline: 22px;
    border-radius: 6px;
    text-decoration: none;
    @include appearAfterMS(500, 750);
  }
}
