.search-field {
  min-width: 100px;
  height: 32px;
  display: flex;
  align-items: center;
  background-color: $color-main-background;
  border-radius: 6px;
  margin: 0 24px;

  &__icon {
    margin-inline-start: 10px;
    color: $color-text-secondary;
    display: flex;
  }

  &__input {
    border: none;
    outline: none;
    background-color: inherit;
    display: inline-block;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    font-size: toRem(14);
    padding-inline: 10px;
    min-height: 32px;

    &::placeholder {
      color: $color-text-secondary;
    }
  }

  &__btn {
    padding: 0 10px;
    height: 100%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
}

[dir="rtl"] {
  .search-field__btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}
