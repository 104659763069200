.qr-code-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;

  &[data-is-pending="true"] {
    animation: breathe-opacity-0_8 1200ms ease-in-out infinite;
  }

  .qr-code {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
    min-height: 400px;
    border-radius: 20px;
    position: relative;

    background-color: $color-white-lilac;

    > .canvas {
      margin-top: 30px;
      width: 340px;
      height: 340px;
      transition: all 350ms ease-in-out;
      &[data-should-blur="true"],
      &[data-should-blur="true"] + .qr-code-source {
        filter: blur(5px);
      }
    }

    > .regenerate-btn {
      appearance: none;
      background-color: $color-white;
      color: $color-primary;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 190px;
      height: 190px;
      border-radius: 50%;
      border: 3px solid $color-shark;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 300ms ease-in-out;
      z-index: 1;
      > span {
        max-width: 85%;
        border-top: 14px;
        font-size: toRem(16);
        overflow: hidden;
      }

      &:hover,
      &:focus {
        transform: scale(0.95);
      }
    }

    .qr-code-source {
      margin-top: 24px;
      margin-bottom: 20px;
      bottom: 10px;
      color: $color-cape-cod;
      font-size: toRem(27);
    }
  }

  .timer-container {
    min-height: 28px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: $color-text-secondary;

    > p {
      font-size: toRem(18);
    }

    &[data-is-critical-interval="true"] {
      color: $color-brick-red;
      > svg {
        animation: breathe-scale-1_1 1.2s ease-in-out infinite;
      }
    }
  }
}

@media (max-width: 980px) {
  .qr-code-container {
    display: flex;
    flex-direction: column-reverse;

    > .timer-container {
      margin-top: unset;
      margin-bottom: 16px;
    }
  }
}
