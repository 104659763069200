.topic-tag {
  padding: 6px 10px;
  border-radius: 100px;
  font-weight: 600;
  font-size: toRem(14);

  &--normal {
    background-color: $color-alice-blue;
    color: $color-science-blue;
  }

  &--danger {
    background-color: $color-chablis;
    color: $color-cardinal;
  }
}
