.dropdown-list {
  padding: 6px 0;
  width: fit-content;
  border-radius: 6px;
  background-color: $color-white;
  border: 1px solid $color-primary;
  animation: fade-in 100ms forwards ease-in;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);

  &__item {
    gap: 10px;
    width: 100%;
    border: none;
    outline: none;
    display: flex;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    line-height: toRem(26);
    color: $color-text-secondary;
    background-color: transparent;
    font-size: toRem(14);

    &:hover,
    &:focus,
    &:active {
      color: $color-text-primary;
      background-color: $color-main-background;
    }
  }
}
