.modal-progressbar {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;

  &__item {
    width: 20px;
    height: 5px;
    background-color: $color-primary;
    border-radius: 10px;
    transition: background-color 100ms ease-in;

    &--pending {
      background-color: $color-mischka;
    }
  }
}
