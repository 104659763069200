@use "sass:color";
@include build-translate-x(modal-form-slide-in, 0vw);

$preview-header-height: 48px;

.upload-file-flash-message {
  max-width: 560px;
  margin-block-start: 20px;
}

.file-salary-distribution-process {
  .authorize-pin-container {
    max-width: 642px;

    #authorize-pin-form.pin {
      padding-block-start: 30px;
      padding-block-end: 10px;
      height: unset;
    }
    .flash-message.flash-message--shown {
      margin-block-start: 20px;
      margin-block-end: 0;
    }
  }
}

.upload-file {
  width: 560px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  margin-block-start: 20px;
  transform: translate(100vw);
  transition: opacity 100ms ease-in-out, transform 300ms ease-in-out;

  &--shown {
    display: flex;
    animation: modal-form-slide-in 300ms ease-in forwards;
  }

  &__source {
    width: 100%;
  }

  .select-box {
    flex-basis: 300px;
  }

  .select-box__handle {
    max-width: unset;
    width: 100%;
  }

  &__date-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .month-picker__input-wrapper {
      width: 300px;
    }
  }

  &__date-title {
    font-weight: 500;
    font-size: toRem(14);
  }

  &__drop,
  &__loading,
  &__preview {
    width: 100%;
    height: 270px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    background: $color-aqua-haze;
    border-radius: 5px;
  }

  &__drop {
    flex-direction: column;
    background: $color-aqua-haze;
    border: 2px dashed transparent;
    transition: background-color 300ms ease-in-out,
      border-color 300ms ease-in-out;

    &--highlight {
      border-color: $color-regent-gray;
      background-color: $color-white;
    }
  }

  &__icon {
    color: $color-text-secondary;
  }

  &__title {
    font-weight: 500;
    margin-block: 18px;
    color: $color-text-secondary;
  }

  &__secondary-title-container {
    position: relative;
    margin-block-end: 18px;
  }

  &__secondary-title-first {
    color: $color-text-secondary;
  }

  &__secondary-title-second {
    color: $color-primary;
    font-weight: 600;
    font-size: toRem(14);
    cursor: pointer;
  }

  &__secondary-title-second--disabled {
    color: $color-text-disabled;
    cursor: default;
  }

  &__note {
    font-size: toRem(14);
    max-width: 222px;
    text-align: center;
    line-height: 18px;
    color: $color-text-secondary;
  }

  &__progress-bar-container {
    width: 100%;
    height: 100%;
    max-width: 250px;
    gap: toRem(16);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__progress-bar-title {
    font-weight: 500;
    font-size: toRem(18);
    color: $color-text-secondary;
  }

  &__preview {
    flex-direction: column;
  }

  &__preview-header {
    width: 100%;
    height: $preview-header-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-text-secondary;
    padding: 0 30px;
    background-color: $color-main-border;
  }

  &__preview-header-title {
    font-weight: 500;
    font-size: toRem(14);
  }

  &__preview-body {
    width: 100%;
    height: calc(100% - $preview-header-height);
    overflow: auto;
    padding: 24px 6px;

    &::-webkit-scrollbar-thumb {
      background: $color-main-border;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 3px;
    }
  }

  &__preview-body-table {
    width: 100%;
    max-width: 430px;
    margin: auto;
    border-spacing: 0;
    border-collapse: collapse;
  }

  &__preview-body-table-header {
    background: color.change($color-emerald, $alpha: 0.6);
  }

  &__preview-body-table-header &__preview-body-table-cell {
    font-weight: 500;
  }

  &__preview-body-table-row {
    height: 38px;
  }

  &__preview-body-table-cell {
    padding: 0 12px;
    border: 1px solid $color-regent-gray;
    font-size: toRem(14);
    color: $color-text-primary;
    text-align: start;
    text-transform: capitalize;
    white-space: nowrap;
  }

  &__preview-body-table-cell:first-child {
    text-align: center;
  }

  &__download-template {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__download-template-text {
    font-weight: 500;
    font-size: toRem(14);
    color: $color-text-secondary;
  }

  &__download-template-button {
    font-weight: 600;
    font-size: toRem(14);
    color: $color-primary;
    background: transparent;
  }
}

.file-salary-distribution-authorization-and-confirmation-step {
  min-width: 600px;
}

.file-salary-distribution-confirmation {
  flex: 1;

  &__heading {
    font-size: toRem(12);
    font-weight: 700;
    color: $color-text-secondary;
    margin-block-start: 10px;
    margin-block-end: 5px;
  }

  &__employees-container {
    padding-block: 12px;
  }

  &__employees-wrapper {
    min-height: 150px;
    max-height: 200px;
    overflow: auto;
    padding-inline-start: 4px;
    padding-inline-end: 8px;
    margin-inline-end: -8px;

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
      border-radius: 6px;
    }
  }

  &__total-salary {
    background-color: $color-main-background;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-block: 12px;
    border-radius: 6px;
  }

  &__total-salary-amount {
    font-weight: 900;
    color: $color-text-primary;
    font-size: toRem(22);
  }

  &__total-salary-currency {
    color: $color-text-disabled;
    font-size: toRem(12);
  }

  &__date {
    margin-top: 16px;
    text-align: center;
    color: $color-text-secondary;
    line-height: 22px;
  }

  &__date-of-creation {
    font-size: toRem(14);
  }

  &__date-of-salary {
    font-size: toRem(14);
    font-weight: 500;
  }
}
