@mixin scrollbar(
  $chromiumWidth: 9,
  $firefoxWidth: auto,
  $trackColor: $color-athens-gray-1,
  $thumbColor: $color-mischka,
  $trackMargin: 0px
) {
  scrollbar-width: #{$firefoxWidth};
  scrollbar-color: #{$thumbColor} #{$trackColor};
  scrollbar-gutter: auto;

  &::-webkit-scrollbar {
    width: #{$chromiumWidth}px;
    height: #{$chromiumWidth}px;
    border-radius: #{$chromiumWidth * 0.8}px;
    overflow: hidden;
  }

  &::-webkit-scrollbar-track {
    background-color: #{$trackColor};
    margin-block: #{$trackMargin};
    margin-inline: #{$trackMargin};
    -webkit-box-shadow: inset 0 0 6px rgba($color: black, $alpha: 0.1);
    border-radius: #{$chromiumWidth * 0.8}px;
    overflow: hidden;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($thumbColor, 0.7);
    border-radius: #{$chromiumWidth * 0.8}px;

    transition-property: background-color;
    transition-timing-function: linear;
    transition-duration: 100ms;

    &:hover {
      background-color: #{$thumbColor};
    }
  }
}
