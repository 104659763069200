@use "sass:color";

$terminals-page-margin: 20px;

.terminals-page {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: auto;
  margin: $terminals-page-margin;
  $height: calc(100% - ($breadcrumbs-height + ($terminals-page-margin * 2)));
  min-height: $height;

  &__search {
    &--hidden {
      overflow: hidden;
      opacity: 0;
      height: 0;
    }
  }

  .statistic-cards {
    display: flex;
    align-items: center;
    column-gap: 24px;
    row-gap: 32px;
    min-height: 110px;
    flex-wrap: wrap;
  }

  &__terminals-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .terminals {
    display: flex;
    flex-wrap: wrap;
    gap: 42px;

    &--no-data {
      display: none;
    }
  }

  .search-field {
    margin: 0 3px;
    background-color: $color-white;
    outline: 1px solid $color-iron;
    height: 40px;
    box-shadow: 0px 2px 8px color.change($color-black, $alpha: 0.05);

    &:focus-within {
      outline: 3px solid color.change($color-primary, $alpha: 0.3);
    }
  }
}
