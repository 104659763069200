@use "sass:color";

.accordion {
  &__header {
    background-color: $color-aqua-haze;
    display: flex;
    padding-inline: 10px;
    align-items: center;

    &:focus-within {
      background-color: color.change($color-athens-gray-5, $lightness: 95%);
    }
  }

  &__header-prefix {
    flex-basis: 90px;
  }

  &__trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    background-color: transparent;
    color: $color-text-secondary;
    padding-block: 10px;

    &:focus-visible,
    &:focus {
      outline: none;
      color: $color-text-primary;
    }

    &:disabled {
      color: $color-text-disabled;
      opacity: 0.7;
      cursor: auto;
    }
  }

  &__title {
    color: $color-text-secondary;
    font-weight: 500;
    font-size: toRem(16);
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__trigger-icon {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    &--rotated {
      transform: rotate(-0.5turn);
    }
  }

  &__content {
    transition: height 300ms cubic-bezier(0.87, 0, 0.13, 1);
    overflow: hidden;
    background-color: $color-white;
  }
}
