@use "sass:color";

.select-box {
  position: relative;
  &__handle {
    @extend .input;

    padding: 6px 8px;
    width: auto;
    min-width: 160px;
    max-width: 240px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    border-radius: 6px;
    border: 1px solid $color-athens-gray-2;
    color: inherit;
    font-size: toRem(13);

    &-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 80%;
      text-align: left;
      pointer-events: none;
    }

    &__indicator {
      pointer-events: none;
      color: $color-text-secondary;

      &--disabled {
        color: $color-text-disabled;
      }
    }
  }

  &--none-selected {
    color: $color-text-disabled;
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    background-color: $color-white;
    border: 1px solid $color-primary;
    border-radius: 6px;
    animation: fade-in 100ms forwards ease-in;
    z-index: 1;
    box-shadow: 0px 2px 8px 0px color.change($color-black, $alpha: 0.05);
    max-height: 300px;

    &--scrollable {
      max-height: 200px;
      overflow-y: auto;
      @include scrollbar(
        $chromiumWidth: 5,
        $firefoxWidth: thin,
        $trackMargin: 1px
      );
    }

    .search-field {
      margin-block: 5px;
    }

    .search-field__input {
      border-start-end-radius: 6px;
      border-end-end-radius: 6px;
    }
  }

  &__items-list {
    list-style: none;
    width: 100%;
    font-size: toRem(14);
    max-height: 36vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $color-mischka transparent;

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
      border-radius: 6px;
    }

    &:not(:only-child) > :last-child {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  &__separator {
    margin-inline: 10px;
    border: none;
    border-top: 1px solid $color-primary;
    height: 0;

    &--my-10 {
      margin-block: 5px 10px;
    }
  }

  &__apply-btn {
    margin-inline: 12px;
    margin-bottom: 10px;
  }
}

.select-box-item {
  padding-block: 4px;
  padding-inline: 10px;
  color: $color-text-secondary;
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;

  &__input {
    min-width: 18px;
    background-color: transparent;
    appearance: none;
    width: 18px;
    height: 18px;
    border: none;

    &--checked {
      background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%2324292E%22%20fill-rule%3D%22evenodd%22%20d%3D%22M13.7799%204.22007c.1404.14062.2193.33125.2193.53%200%20.19875-.0789.38937-.2193.53L6.52985%2012.5301c-.14063.1404-.33125.2193-.53.2193-.19875%200-.38938-.0789-.53-.2193l-3.25-3.25003c-.13248-.14218-.2046-.33022-.20118-.52453.00343-.1943.08214-.37968.21956-.5171.13741-.13741.3228-.21612.5171-.21955.1943-.00343.38234.0687.52452.20118l2.72%202.72003%206.72005-6.72003c.1406-.14045.3312-.21934.53-.21934.1987%200%20.3894.07889.53.21934Z%22%20clip-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    &:disabled {
      @extend .select-box-item__input--checked;
      opacity: 0.3;
    }
  }

  &__text {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    line-height: 2;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:hover:not(&--disabled),
  &:focus:not(&--disabled),
  &--selected {
    color: $color-text-primary;
  }

  &:hover,
  &:focus {
    background-color: $color-aqua-haze;
  }

  &:focus-visible {
    outline: none;
  }

  &--disabled {
    cursor: default;
    color: $color-text-disabled;
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

[dir="rtl"] {
  .select-box {
    &__handle {
      &-label {
        text-align: right;
      }
    }
  }
}
