.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;

  &__title {
    margin-top: 40px;
    margin-bottom: 10px;
    font-size: toRem(28);
    font-weight: 500;
    color: $color-text-secondary;
    @include appearAfterMS(500, 500);
  }

  &__description {
    font-size: toRem(18);
    color: $color-text-disabled;
    @include appearAfterMS(500, 650);
  }

  &__illustration {
    & .path-1 {
      stroke-dashoffset: 571.6368408203125px;
      stroke-dasharray: 571.6368408203125px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.6s;
    }

    &.active .path-1 {
      stroke-dashoffset: 0;
      fill: rgb(225, 228, 232);
    }

    & .path-2 {
      stroke-dashoffset: 266px;
      stroke-dasharray: 266px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.15s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.5s;
    }

    &.active .path-2 {
      stroke-dashoffset: 0;
    }

    & .path-3 {
      stroke-dashoffset: 15px;
      stroke-dasharray: 15px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.3s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.8s;
    }

    &.active .path-3 {
      stroke-dashoffset: 0;
    }

    & .path-4 {
      stroke-dashoffset: 45px;
      stroke-dasharray: 45px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1)
          0.44999999999999996s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.9s;
    }

    &.active .path-4 {
      stroke-dashoffset: 0;
    }

    & .path-5 {
      stroke-dashoffset: 18px;
      stroke-dasharray: 18px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.6s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1s;
    }

    &.active .path-5 {
      stroke-dashoffset: 0;
    }

    & .path-6 {
      stroke-dashoffset: 5px;
      stroke-dasharray: 5px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 0.75s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.1s;
    }

    &.active .path-6 {
      stroke-dashoffset: 0;
    }

    & .path-7 {
      stroke-dashoffset: 13px;
      stroke-dasharray: 13px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1)
          0.8999999999999999s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.2000000000000002s;
    }

    &.active .path-7 {
      stroke-dashoffset: 0;
    }

    & .path-8 {
      stroke-dashoffset: 25.154226303100586px;
      stroke-dasharray: 25.154226303100586px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 1.05s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.3s;
    }

    &.active .path-8 {
      stroke-dashoffset: 0;
      fill: rgb(63, 94, 175);
    }

    & .path-9 {
      stroke-dashoffset: 46.655555725097656px;
      stroke-dasharray: 46.655555725097656px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 1.2s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.4s;
    }

    &.active .path-9 {
      stroke-dashoffset: 0;
      fill: rgb(63, 94, 175);
    }

    & .path-10 {
      stroke-dashoffset: 42.83076095581055px;
      stroke-dasharray: 42.83076095581055px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1)
          1.3499999999999999s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.5s;
    }

    &.active .path-10 {
      stroke-dashoffset: 0;
      fill: rgb(63, 94, 175);
    }

    & .path-11 {
      stroke-dashoffset: 87.79695129394531px;
      stroke-dasharray: 87.79695129394531px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 1.5s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.6s;
    }

    &.active .path-11 {
      stroke-dashoffset: 0;
      fill: rgb(63, 94, 175);
    }

    & .path-12 {
      stroke-dashoffset: 319.828369140625px;
      stroke-dasharray: 319.828369140625px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 1.65s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.7000000000000002s;
    }

    &.active .path-12 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
    }

    & .path-13 {
      stroke-dashoffset: 318.38043212890625px;
      stroke-dasharray: 318.38043212890625px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1)
          1.7999999999999998s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.8000000000000003s;
    }

    &.active .path-13 {
      stroke-dashoffset: 0;
      fill: rgb(255, 255, 255);
    }

    & .path-14 {
      stroke-dashoffset: 9.81024980545044px;
      stroke-dasharray: 9.81024980545044px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 1.95s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 1.9s;
    }

    &.active .path-14 {
      stroke-dashoffset: 0;
    }

    & .path-15 {
      stroke-dashoffset: 3.414213538169861px;
      stroke-dasharray: 3.414213538169861px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 2.1s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2s;
    }

    &.active .path-15 {
      stroke-dashoffset: 0;
    }

    & .path-16 {
      stroke-dashoffset: 17.81138801574707px;
      stroke-dasharray: 17.81138801574707px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 2.25s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2.1s;
    }

    &.active .path-16 {
      stroke-dashoffset: 0;
    }

    & .path-17 {
      stroke-dashoffset: 7.7008771896362305px;
      stroke-dasharray: 7.7008771896362305px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 2.4s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2.2s;
    }

    &.active .path-17 {
      stroke-dashoffset: 0;
    }

    & .path-18 {
      stroke-dashoffset: 29.197608947753906px;
      stroke-dasharray: 29.197608947753906px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 2.55s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2.3000000000000003s;
    }

    &.active .path-18 {
      stroke-dashoffset: 0;
      fill: rgb(88, 96, 105);
    }

    & .path-19 {
      stroke-dashoffset: 54.58225631713867px;
      stroke-dasharray: 54.58225631713867px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1)
          2.6999999999999997s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2.4s;
    }

    &.active .path-19 {
      stroke-dashoffset: 0;
    }

    & .path-20 {
      stroke-dashoffset: 43.05771255493164px;
      stroke-dasharray: 43.05771255493164px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 2.85s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2.5s;
    }

    &.active .path-20 {
      stroke-dashoffset: 0;
    }

    & .path-21 {
      stroke-dashoffset: 27px;
      stroke-dasharray: 27px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 3s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2.6s;
    }

    &.active .path-21 {
      stroke-dashoffset: 0;
    }

    & .path-22 {
      stroke-dashoffset: 36.00115966796875px;
      stroke-dasharray: 36.00115966796875px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 3.15s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2.7s;
    }

    &.active .path-22 {
      stroke-dashoffset: 0;
    }

    & .path-23 {
      stroke-dashoffset: 28.495677947998047px;
      stroke-dasharray: 28.495677947998047px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 3.3s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2.8000000000000003s;
    }

    &.active .path-23 {
      stroke-dashoffset: 0;
    }

    & .path-24 {
      stroke-dashoffset: 18px;
      stroke-dasharray: 18px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1)
          3.4499999999999997s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 2.9000000000000004s;
    }

    &.active .path-24 {
      stroke-dashoffset: 0;
    }

    & .path-25 {
      stroke-dashoffset: 85.2283706665039px;
      stroke-dasharray: 85.2283706665039px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1)
          3.5999999999999996s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 3.0000000000000004s;
    }

    &.active .path-25 {
      stroke-dashoffset: 0;
      fill: rgb(63, 94, 175);
    }

    & .path-26 {
      stroke-dashoffset: 96.2038803100586px;
      stroke-dasharray: 96.2038803100586px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 3.75s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 3.1s;
    }

    &.active .path-26 {
      stroke-dashoffset: 0;
      fill: rgb(63, 94, 175);
    }

    & .path-27 {
      stroke-dashoffset: 85.22834777832031px;
      stroke-dasharray: 85.22834777832031px;
      fill: transparent;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 3.9s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 3.2s;
    }

    &.active .path-27 {
      stroke-dashoffset: 0;
      fill: rgb(63, 94, 175);
    }

    & .path-28 {
      stroke-dashoffset: 248px;
      stroke-dasharray: 248px;
      transition: stroke-dashoffset 0.5s cubic-bezier(0.19, 1, 0.22, 1) 4.05s,
        fill 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) 3.3000000000000003s;
    }

    &.active .path-28 {
      stroke-dashoffset: 0;
    }
  }

  &__action-link {
    margin-top: 60px;
    padding-block: 10px;
    padding-inline: 22px;
    border-radius: 6px;
    text-decoration: none;
    @include appearAfterMS(500, 750);
  }
}
