.bank-transfer__container {
  .bank-transfer-authorization-and-confirmation-step {
    min-width: 550px;
  }

  .authorize-pin-container {
    width: 600px;

    #authorize-pin-form.pin {
      padding-block-start: 30px;
      padding-block-end: 10px;
      height: unset;
    }
    .flash-message.flash-message--shown {
      margin-block-start: 20px;
      margin-block-end: 0;
    }
  }
}

.bank-transfer-information-step {
  width: 640px;

  &__iban {
    text-transform: uppercase;

    &::-webkit-input-placeholder {
      text-transform: none;
    }
    &:-moz-placeholder {
      text-transform: none;
    }
    &::-moz-placeholder {
      text-transform: none;
    }
    &:-ms-input-placeholder {
      text-transform: none;
    }
    &::placeholder {
      text-transform: none;
    }
  }

  &__check-button {
    aspect-ratio: 1 / 1;
    height: 32px;
  }

  &__brief-label {
    align-self: flex-start;
    padding-top: 8px;
  }

  .select-box {
    &__handle {
      width: 100%;
      max-width: unset;
    }

    &__list-wrapper {
      z-index: 2;
    }
  }

  .modal-form-control__input-element {
    flex-basis: 400px;

    &-bic-code {
      padding: 0;
    }
  }

  .modal-form-control__input-element-wrapper {
    display: flex;
    column-gap: 15px;
    flex-basis: 400px;

    &--loading .input {
      background-color: $color-athens-gray-3;
    }
  }

  &__input-iban-info-wrapper {
    display: flex;
    column-gap: 4px;
    align-items: center;
  }

  &__input-iban-info-wrapper--disabled {
    cursor: auto;
    color: $color-text-disabled;
    background-color: $color-aqua-haze;

    & > input {
      background-color: inherit;
    }
  }

  &__input-prefix-iban-info {
    width: 16px;
    height: 16px;
  }

  &__input-iban-info {
    width: 100%;
    border: none;
    height: 100%;
    cursor: inherit;
    font-size: toRem(14);
  }

  &__brief-textarea {
    resize: none;
    padding: 8px;
    height: 125px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $color-iron;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $color-regent-gray;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $color-text-secondary;
    }
  }

  .attachment-input {
    flex-basis: 400px;
    max-width: 400px;
  }
}

.bank-transfer-overview-step-summary {
  flex-grow: 1;

  &__title {
    font-weight: 700;
    padding-bottom: 5px;
    margin-bottom: 15px;
    font-size: toRem(12);
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: $color-text-secondary;
    border-bottom: 1px solid $color-main-border;
  }

  &__header {
    padding: 5px;
    font-weight: 500;
    text-align: center;
    font-size: toRem(12);
    color: $color-text-primary;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: $color-main-background;
  }

  &__body {
    font-weight: 500;
    min-height: 150px;
    text-align: center;
    font-style: normal;
    margin-block: 10px;
    font-size: toRem(12);

    & > * {
      row-gap: 5px;
      display: flex;
      flex-direction: column;
    }
  }

  &__body-personal-info {
    margin-top: 15px;
  }

  &__body-bank-info {
    margin-block: 10px;
  }

  &__body-name {
    @include end-with-ellipsis;
    font-size: toRem(16);
    margin-inline: auto;
    font-weight: 500;
    width: 80%;
  }

  &__body-normal-text {
    @include end-with-ellipsis;
    width: 80%;
    margin-inline: auto;
    color: $color-text-secondary;
  }

  &__footer {
    row-gap: 5px;
    display: flex;
    min-height: 65px;
    font-weight: 900;
    border-radius: 6px;
    text-align: center;
    padding-block: 10px;
    font-size: toRem(22);
    flex-direction: column;
    background-color: $color-main-background;

    & > p:first-child {
      letter-spacing: 0.05em;
    }

    & > p:last-child {
      font-weight: 400;
      font-size: toRem(12);
      color: $color-text-secondary;
    }
  }

  &__date {
    margin-top: 10px;
    text-align: center;
    color: $color-text-secondary;
  }
}
