.create-user-process {
  .authorize-pin-container {
    width: 600px;

    #authorize-pin-form.pin {
      padding-block-start: 30px;
      padding-block-end: 10px;
      height: unset;
    }
    .flash-message.flash-message--shown {
      margin-block-start: 20px;
      margin-block-end: 0;
    }
  }

  .create-user-agent-confirmation-step {
    .create-user-confirmation__info-container {
      margin-inline-start: 29px;
    }
  }
}

.create-user-information-step {
  min-width: 600px;

  @include password-strength-styles();

  .input,
  .phone-number,
  .password-input {
    flex-basis: 400px;
  }

  .password-strength,
  .select-box {
    width: 400px;
  }

  .select-box__handle {
    flex-basis: unset;
    width: 100%;
    max-width: unset;
  }

  .select-box__list-wrapper {
    bottom: 33px;
  }
}

.create-user-authorization-and-confirmation-step {
  min-width: 550px;

  .create-user-confirmation {
    flex: 1;

    &__heading {
      font-size: toRem(14);
      font-weight: 700;
      color: $color-text-secondary;
      margin-block-start: 10px;
      margin-block-end: 5px;
    }

    .process-modal__separator {
      margin-block-end: 25px;
    }

    &__info-row {
      display: flex;
      gap: 18px;
      color: $color-text-primary;

      &--center {
        justify-content: center;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__info-label {
      flex-basis: 130px;
      color: inherit;
      text-align: end;
    }

    &__info-value {
      max-width: 200px;
      word-break: break-all;
      color: inherit;
      font-weight: 500;

      &--flex-basis {
        flex-basis: 160px;
      }
    }

    &__created-at {
      text-align: center;
      color: $color-text-secondary;
      margin-block-start: 38px;
    }
  }
}
