@use "sass:color";

.stacked-balance-chart {
  position: relative;

  .min-max-balances {
    position: absolute;
    top: -16px;
    right: 20px;
    background-color: color.change($color-white, $alpha: 0.9);
    padding: 8px;
    border-radius: 6px;
    font-size: toRem(14);
    display: flex;
    flex-direction: column;
    gap: 6px;

    &--rtl {
      right: initial;
      left: 20px;
    }
  }
}

.stacked-balance-chart-wrapper {
  margin-inline: auto;
  overflow: hidden;
}

.stacked-balance-chart-legend {
  display: flex;
  justify-content: center;
  gap: 25px;

  &__item {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
