@include buildVerticalMovementAnimation(square-card, -15px);

.text-limitation {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.square-card {
  $root: &;
  @extend .card;
  width: 224px;
  height: 234px;
  padding: 12px;
  text-align: center;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 100ms, color 100ms;
  animation: fade-in 500ms forwards,
    vertical-movement-square-card 500ms forwards;

  &#{$root}--inactive {
    cursor: default;
    background-color: $color-main-border;

    &:hover {
      background-color: $color-main-border;

      #{$root}__option {
        color: $color-text-secondary;
      }

      #{$root}__title {
        color: $color-text-primary;
      }

      #{$root}__second-title {
        color: $color-text-primary;
      }

      #{$root}__subtitle {
        color: $color-text-secondary;
      }
    }

    #{$root}__initials {
      background-color: $color-white;
    }

    #{$root}__title {
      color: $color-text-primary;
    }

    #{$root}__second-title {
      color: $color-text-primary;
    }

    #{$root}__subtitle {
      overflow: unset;
      white-space: unset;
      font-weight: 400;
      line-height: 14px;
      font-size: toRem(12);
      color: $color-text-secondary;
    }
  }

  &:hover {
    background-color: $color-primary;

    #{$root}__option {
      color: $color-mischka;
    }
  }

  &__option {
    @extend .button;
    @extend .button--iconed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    align-self: flex-end;
    color: $color-text-secondary;
    background-color: transparent;

    &--invisible {
      visibility: hidden;
    }

    #{$root} > &:hover {
      color: $color-white;
    }
  }

  &__initials {
    width: 65px;
    height: 65px;
    margin: 20px 0;
    font-size: toRem(22);
    color: $color-text-secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 50%;
    text-transform: uppercase;
    background-color: $color-main-border;

    &--withLabel {
      margin-block: 6px;
    }
  }

  &__label {
    margin-block-start: 4px;
    margin-block-end: 2px;
    padding-block: 4px;
    padding-inline: 8px;
    background-color: $color-aqua-haze;
    border-radius: 100px;
    font-size: toRem(12);
    font-weight: 600;
    color: $color-text-secondary;
  }

  &__title {
    line-height: 24px;
    font-size: toRem(18);
    color: $color-primary;

    #{$root}:hover & {
      color: $color-white;
    }
  }

  &__second-title {
    padding-block: 3px;
    line-height: 16px;
    font-size: toRem(14);
    font-weight: 400;
    color: $color-text-disabled;

    #{$root}:hover & {
      color: $color-white;
    }
  }

  &__subtitle {
    line-height: 24px;
    font-size: toRem(20);
    color: $color-quaternary;

    #{$root}:hover & {
      color: $color-white;
    }
  }
}
