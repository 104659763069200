.pin-container {
  width: 640px;

  .pin__input--content-hidden {
    font-size: toRem(70);
  }

  @-moz-document url-prefix() {
    .pin__input--content-hidden {
      font-size: 480%;
    }
  }
  .pin {
    padding-block-start: 30px;
    padding-block-end: 18px;
    min-height: 110px;

    &__input {
      padding-top: 8px;
      width: 60px;
      height: 80px;
    }
  }

  .flash-message--shown {
    padding: 30px;
    margin-block-start: 20px;
  }
}

.flash-message--verify-step {
  #verify-pin-form.pin {
    padding-block-start: 16px;
  }
  .flash-message {
    margin-block-start: 0;
  }
}
