@use "sass:color";

.settings-sidebar {
  width: 320px;

  & .select-box__handle {
    max-width: unset;
    width: 100%;
  }
}

.account-settings {
  &__header {
    padding-inline: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-start: 20px;
    text-align: center;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  &__user-name {
    margin-top: 12px;
    font-weight: 500;
    font-size: toRem(16);
    color: $color-text-primary;
    unicode-bidi: plaintext;
  }

  &__user-role {
    margin-top: 4px;
    font-weight: 400;
    font-size: toRem(14);
    color: $color-text-primary;
  }

  &__section-header {
    padding-inline-start: 16px;
    text-transform: uppercase;
    font-size: toRem(12);
    font-weight: 500;
    color: $color-text-disabled;
  }

  &__actions {
    padding-inline-start: 8px;
    padding-inline-end: 25px;
    margin-block-start: 40px;
    margin-block-end: 30px;
  }

  &__action-list {
    margin-top: 13px;
  }

  &__action {
    display: flex;
    align-items: center;
    gap: 15px;
    color: $color-text-secondary;
    font-size: toRem(14);
    font-weight: 600;
    padding-block: 7px;
    padding-inline: 16px;
    transition: border 25ms ease-in-out;

    &:last-child {
      color: $color-mandy;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid $color-athens-gray-2;
      border-radius: 6px;
      box-shadow: 0px 1px 0px color.change($color-black, $alpha: 0.15);
    }
  }

  &__settings {
    padding-inline-start: 24px;
    padding-inline-end: 25px;

    &-header {
      text-transform: uppercase;
      font-size: toRem(12);
      font-weight: 500;
      color: $color-text-disabled;
    }

    &-list {
      margin-top: 13px;
    }

    &-lang .select-box .select-box__handle {
      font-size: toRem(14);
      color: $color-text-primary;
      width: 100%;
      padding-block: 8px;
      margin-top: 5px;
    }
  }

  &__setting {
    display: flex;
    align-items: center;
    gap: 15px;
    color: $color-text-secondary;
    font-size: toRem(14);
    font-weight: 600;
    padding-block: 7px;
    transition: border 25ms ease-in-out;
  }
}

[dir="rtl"] .account-settings__logout-icon {
  transform: rotate(180deg);
}
