.no-data {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  @include appearAfterMS(500, 1000);

  &--no-delay {
    animation-delay: 0ms;
  }

  &__title {
    margin-top: 10px;
    color: $color-text-secondary;
    font-weight: 500;
    font-weight: toRem(28);
  }
  &__text {
    margin-top: 8px;
    color: $color-text-disabled;
    max-width: 40ch;
    text-align: center;
  }
}
