@use "sass:color";

.process-modal {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: color.change($color-black, $alpha: 0.2);
  width: 100%;
  height: 100%;
  z-index: 2;

  &--shown {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fade-in 100ms ease-in forwards;
  }

  &__container {
    position: relative;
    background-color: $color-white;
    border: 1px solid $color-main-border;
    border-radius: 6px;
    padding: 20px;
    min-width: 400px;
    overflow-x: hidden;
    box-shadow: 0px 2px 8px 0px color.change($color-black, $alpha: 0.05);

    &::-webkit-scrollbar-thumb {
      background: $color-mischka;
      border-radius: 6px;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
      border-radius: 6px;
    }

    > .progress-bar-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 10px;
  }

  &__title {
    font-size: toRem(20);
    color: $color-text-secondary;
    font-weight: 500;
  }

  &__subtitle {
    font-size: toRem(14);
    color: $color-text-secondary;
  }

  &__separator {
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    background-color: $color-main-border;
    width: 100%;
  }

  &__body {
    padding-block: 20px;
    transition: height 200ms ease-in;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    & > .primary-actions {
      display: flex;
      column-gap: 15px;
    }
  }

  &__button {
    padding: 10px 20px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 4px;

    &--hidden {
      display: none;
    }
  }

  .next-button-container {
    &--hidden {
      display: none;
    }

    &--loading {
      position: relative;
      .spinner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}
